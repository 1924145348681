import { rest } from '../restWrapper';

var getAllModelData = async (modelName) => {
    try {
        let allData = await rest('/' + modelName, 'GET');
        return allData;
    } catch (error) {
        throw error;
    }
};

var saveModelData = async (modelName, object) => {
    try {
        let newItem = await rest('/' + modelName, 'POST', object);
        return newItem;
    } catch (error) {
        throw error;
    }
};

var getSpecificModelRow = async (modelName, id) => {
    try {
        let target = await rest('/' + modelName + '/' + id, 'GET');
        return target;
    } catch (error) {
        throw error;
    }
};

var deleteModelData = async (modelName, id) => {
    try {
        let delItem = await rest('/' + modelName + '/' + id, 'DELETE');
        return delItem;
    } catch (error) {
        throw error;
    }
};

export { getAllModelData, saveModelData, getSpecificModelRow, deleteModelData };
