import {translate} from "./translations"

const reservationEnums = {
    earlyBird: 0,
    confirmed: 1,
    checkedIn: 2,
    canceled: 3,
    closed: 4,
    autoBlocked: 5,
};

const indexToReservationEnum = {
    0: 'earlyBird',
    1: 'confirmed',
    2: 'checkedIn',
    3: 'canceled',
    4: 'closed',
    5: 'autoBlocked',
};

var reservationStatusEnumsDesc = {
    0: translate("Early bird"),
    1: translate("Confirmed"),
    2: translate("Checked in"),
    3: translate("Canceled"),
    4: translate("Closed"), //paymentPending or paid
    5: translate("Auto blocked"),
};


const reservationStatusColors = {
    0: 'text-info',
    1: 'text-primary',
    2: 'text-success',
    3: 'text-warning',
    4: 'text-secondary',
    5: 'text-danger',
};

const reservationStatusBackgrounds = {
    0: 'bg-info',
    1: 'bg-primary',
    2: 'bg-success',
    3: 'bg-warning',
    4: 'bg-secondary',
    5: 'bg-danger',
};

const reservationStatusColorsHEX = {
    0: '#17a2b8', //earlyBird
    1: '#007bff', //confirmed
    2: '#28a745', //checkedIn
    3: '#ffc105', //null,//"#ffc107", //canceled
    4: '#6c757d', //closed
    5: '#dc3545', //autoCanceled
};

const reservationStatusColorsHEXCondo = {
    0: 'purple', //earlyBird
    1: 'purple', //confirmed
    2: 'purple', //checkedIn
    3: '#ffc105', //null,//"#ffc107", //canceled
    4: '#6c757d', //closed
    5: 'purple', //autoCanceled
};


const reservationInvoicedStatusEnum={
    0:'-',
    1:'Fully',
    2:'Partialy'
}



export {
    reservationEnums,
    indexToReservationEnum,
    reservationStatusEnumsDesc,
    reservationStatusColors,
    reservationStatusColorsHEX,
    reservationStatusBackgrounds,
    reservationInvoicedStatusEnum,
    reservationStatusColorsHEXCondo,
    
};
