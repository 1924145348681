import CountryCodes from './countryCodes.json';

const indexed = {} as any;
var isIndexed = false;

if (!isIndexed) {
    CountryCodes.forEach((c) => {
        indexed[c['country-code']] = c;
    });
}

isIndexed = true;

export default indexed;
