import SocketClientClass from './client';

let client = null;
if (!window._ltSocket) {
    //singleton
    window._ltSocket = new SocketClientClass();
}

client = window._ltSocket;
export default client;
