import React, { Component } from "react";
import _ from "lodash"

export default function asyncComponent(importComponent, hasBackground) {
  class AsyncComponent extends Component {
    constructor(props) {
      super(props);

      this.state = {
        component: null
      };
    }

    async componentDidMount() {
      const { default: component } = await importComponent();

      this.setState({
        component: component
      });
    }

    render() {
      const C = this.state.component;

      return C ? <C {...this.props} /> : <div className={_.isUndefined(hasBackground) ? "hidden" : "content content-w-background col-md-9"}></div>;
    }
  }

  return AsyncComponent;
}