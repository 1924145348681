import React, { useState } from 'react';
import { getRoomSRCStatusIconByLastSeenTimestamp } from '../../data/controllers/SRCStatus';
import rest from '../../data/restWrapper';
import BasicSRCSettings from '../roomInfo/components/basicSRCSettingsModal';
import { setModalContent } from '@components/modal';
import translate from '@data/translations';
import useStore from '@data/state/zustand';
import { closeAllOldEvents, getActiveEventsWithoutAssignedRoom } from '@pages/allRoomEvents/api';
import ConfirmModal from '@components/confirmModal';

const calcDashboard = ({ rooms, eventTickets, src, SRCEnums, alarmInterpreter }) => {
    const result = [];
    const roomModeInterpreter = alarmInterpreter?.roomModeInterpreter ? alarmInterpreter.roomModeInterpreter : {};
    if (!SRCEnums.ioNamedEnum) {
        return result;
    }
    const dashboardItemTemplate = {
        roomInfoId: null,
        roomName: '',
        lastSeenTimestamp: null,
        inputs: {},
        outputs: {},

        minibar: false,
        cleaningStatus: '', //cleaningStatus.noActionTaken,
        minTemperature: 5,
        maxTemperature: 26,
        targetTemperature: 22,
        currentTemperature: 21,
        roomMode: '',
        roomDeviceId: null,
        roomDevicePosition: null,
    };

    const evTicketRoomMap = {};
    eventTickets &&
        eventTickets.forEach((ev) => {
            if (evTicketRoomMap[ev.remoteDeviceId] === undefined) {
                evTicketRoomMap[ev.remoteDeviceId] = {};
            }
            evTicketRoomMap[ev.remoteDeviceId][ev.ruleName] = ev;
        });
    const ioKeys = Object.keys(SRCEnums?.ioNamedEnum);

    const srcMap = {};
    src &&
        src.forEach((s) => {
            srcMap[s.remoteDeviceId] = s;
        });
    for (const room of rooms) {
        if (room?.id) {
            const roomDevicesIds = ['remoteDeviceId', 'remoteDeviceId2', 'remoteDeviceId3'];
            //ili nema HW-a
            for (let i = 0; i < roomDevicesIds.length; i++) {
                let device = roomDevicesIds[i];
                const deviceId = room[device];
                if (deviceId && evTicketRoomMap[deviceId]) {
                    const temp = { ...dashboardItemTemplate, inputs: {} };
                    temp.roomInfoId = room.id;
                    temp.roomName = room.name;
                    temp.roomDeviceId = deviceId;
                    temp.roomDevicePosition = i + 1;
                    temp.lastSeenTimestamp = srcMap[deviceId] ? srcMap[deviceId].lastSeenTimestamp : 0;

                    temp.thReadoutTimestamp = srcMap[deviceId] ? srcMap[deviceId].thReadoutTimestamp : null;
                    temp.lastTh1Readout = srcMap[deviceId] ? srcMap[deviceId].lastTh1Readout : null;
                    temp.lastTh2Readout = srcMap[deviceId] ? srcMap[deviceId].lastTh2Readout : null;
                    temp.lastTh3Readout = srcMap[deviceId] ? srcMap[deviceId].lastTh3Readout : null;

                    temp.lastWire1Readout = srcMap[deviceId] ? srcMap[deviceId].lastWire1Readout : null;
                    temp.lastWire2Readout = srcMap[deviceId] ? srcMap[deviceId].lastWire2Readout : null;
                    temp.lastWire3Readout = srcMap[deviceId] ? srcMap[deviceId].lastWire3Readout : null;
                    temp.wireReadoutTimestamp = srcMap[deviceId] ? srcMap[deviceId].wireReadoutTimestamp : null;

                    temp.lastCleaningDate = new Date(room.cleaningModeActivatedTs).toISOString(true).substr(0, 10);

                    const smartRoomController = src.find((s) => s.remoteDeviceId === deviceId);

                    for (let i = 0; i < ioKeys.length; i++) {
                        const ioName = ioKeys[i];
                        const isInput = SRCEnums?.inputs?.includes(ioKeys[i]); //_.includes(SRCEnums.inputs, ioKeys[i]); //else are output
                        const inputOpenOutputClosed = smartRoomController?.SRCIOStates & (1 << (31 - i));
                        if (isInput) {
                            temp.inputs[ioName] = inputOpenOutputClosed; //false for closed
                        } else {
                            temp.outputs[ioName] = inputOpenOutputClosed === 0; // false for off
                        }
                    }

                    const alarmTypes = [
                        'alarm_ecoRoomMode',
                        'alarm_comfortRoomMode',
                        'alarm_preComfortRoomMode',
                        'alarm_cleaningRoomMode',
                    ];

                    let roomModeByFirstRemoteDeviceId = null; //rome mode gledamo iz prvog kontrela na sobi - room.remoteDeviceId

                    for (const alarmType of alarmTypes) {
                        const alarm = evTicketRoomMap[deviceId][alarmType];
                        if (alarm && alarm?.remoteDeviceId === deviceId) {
                            roomModeByFirstRemoteDeviceId = alarm;
                        }
                    }

                    if (
                        roomModeByFirstRemoteDeviceId &&
                        roomModeByFirstRemoteDeviceId?.ruleName === 'alarm_ecoRoomMode'
                    ) {
                        temp.roomMode = roomModeInterpreter['ECO'];
                    } else if (
                        roomModeByFirstRemoteDeviceId &&
                        roomModeByFirstRemoteDeviceId?.ruleName === 'alarm_comfortRoomMode'
                    ) {
                        temp.roomMode = roomModeInterpreter['COMFORT'];
                    } else if (
                        roomModeByFirstRemoteDeviceId &&
                        roomModeByFirstRemoteDeviceId?.ruleName === 'alarm_preComfortRoomMode'
                    ) {
                        temp.roomMode = roomModeInterpreter['PRECOMFORT'];
                    } else if (
                        roomModeByFirstRemoteDeviceId &&
                        roomModeByFirstRemoteDeviceId?.ruleName === 'alarm_cleaningRoomMode'
                    ) {
                        temp.roomMode = 'CLN';
                    }

                    temp.minibar = evTicketRoomMap[deviceId]['customAlarm_minibarNotClosed'] !== undefined;
                    result.push(temp);
                }
            }
        } else {
        }
    }
    return result;
};

const HwRoomView = (props) => {
    const [isFetchTemperatureDisabled, setIsFetchTemperatureDisabled] = useState(false);
    const [areTemperatureValuesShown, setShowTemperatureValues] = useState(false);

    const { history, rooms } = props;

    const filteredRoomsIds = rooms.map((r) => Number(r.id));

    const eventTickets = useStore((appState) => appState.model.RoomEventTicket);
    const src = useStore((appState) => appState.model.SmartRoomController);
    const SRCEnums = useStore((appState) => appState.SRCEnums);
    const alarmInterpreter = useStore((appState) => appState.state.alarmInterpreter);
    const columnsNumber = useStore((appState) => appState.uiStates.columnsNumber);
    const parsedRoomInfo = useStore((appState) => appState.monkeys.parsedRoomInfo())?.filter((pr) =>
        filteredRoomsIds.includes(Number(pr.id))
    );
    const [events, setEvents] = useState([]);

    const filteredRooms = parsedRoomInfo
        ?.filter((i) => (i ? i.availableForRent : null))
        ?.filter((f) => f !== null)
        ?.sort((a, b) => (a.name > b.name ? 1 : -1));
    const srcStatusDashboard = calcDashboard({ eventTickets, src, SRCEnums, rooms: filteredRooms, alarmInterpreter });

    let numberOfItems = srcStatusDashboard.length;
    let maxNumberOfItemsPerColumn = Math.ceil(numberOfItems / columnsNumber);

    let clmnArray = [];

    React.useEffect(() => {
        const loadData = async () => {
            try {
                const data = await rest('/getGeneralSettingByKey/alarmInterpreter');
                if (data !== null) {
                    useStore.getState().setNestedProperty(['state', 'alarmInterpreter'], JSON.parse(data.params));
                }
                await findActiveEventsWithoutAssignedRoom();
            } catch (err) {
                console.log('error', err);
            }
        };

        loadData().catch((err) => {
            console.warn(err);
        });
    }, []);

    for (let i = 0; i < columnsNumber; i++) {
        clmnArray.push(i);
    }

    const handleShowBasicOptions = (roomInfoId, roomsName, roomDeviceId) => {
        const room = filteredRooms.find((r) => r.id === roomInfoId);

        let roomName = room.name;
        let basicSRCSettings = (
            <BasicSRCSettings
                roomInfoId={room.id}
                // key={roomInfo.id}
                // reservation={this.props.reservation}
                remoteDeviceId={roomDeviceId}
            />
        );
        setModalContent(
            basicSRCSettings,
            <span>
                {window.translate('Room settings') + ' - '} <b>{roomName}</b>
            </span>,
            true,
            'modal-xl'
        );
    };

    const isRoomOnline = (lastSeenTimestamp) => {
        if (lastSeenTimestamp) {
            const lastSeenMilisecondsFromNow = Date.now() - lastSeenTimestamp;
            if (lastSeenMilisecondsFromNow > 20 * 60 * 1000) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    };

    function chunkArray(arr, size) {
        const chunks = [];
        for (let i = 0; i < arr.length; i += size) {
            chunks.push(arr.slice(i, i + size));
        }
        return chunks;
    }

    const fetchTemperatureValues = async (type) => {
        try {
            setIsFetchTemperatureDisabled(true);
            const maxRoomPerRequest = 50;
            // const roomIds = filteredRooms.map((room) => room.id);
            const controlersIds = [];
            // saljem samo online id-jeve soba
            // saljem samo online id-jeve soba
            src &&
                src.forEach((contr) => {
                    if (isRoomOnline(contr.remoteDeviceId)) {
                        controlersIds.push(contr.remoteDeviceId);
                    }
                });
            const ctrlsBatches = chunkArray(controlersIds, maxRoomPerRequest);
            const roomThermostatTemperatures = {};
            async function fetchThermostatValuesForRooms(roomBatch) {
                const data = await rest('/src/fetchThermostatValuesForRooms', 'POST', {
                    controlersIds: roomBatch,
                    type: type,
                });
                for (const iterator in data) {
                    roomThermostatTemperatures[iterator] = data[iterator];
                }
            }
            const fetchPromises = ctrlsBatches.map(fetchThermostatValuesForRooms);
            await Promise.all(fetchPromises);

            setIsFetchTemperatureDisabled(false);
        } catch (error) {
            console.log(error);
        }
    };

    const findActiveEventsWithoutAssignedRoom = async () => {
        //provjeri ima li aktivnih evenata koje treba zatvoriti/izbrisati
        //ako je kontroler nije nakacen ni na jednu sobu
        const events = await getActiveEventsWithoutAssignedRoom();
        setEvents(events);
    };

    return (
        <div className="w-100">
            <div className="d-flex justify-content-center align-items-center my-1 hardware-rooms">
                <div className="flex-1">
                    <div
                        className="px-3 btn increase-decrease-button pointer"
                        onClick={() => {
                            if (columnsNumber > 1) {
                                useStore.getState().setNestedProperty(['uiStates', 'columnsNumber'], columnsNumber - 1);
                            }
                        }}
                    >
                        -
                    </div>
                    <div className=" px-1 d-inline-block">
                        {translate('Columns')}: {columnsNumber}
                    </div>
                    <div
                        className="px-3 btn increase-decrease-button pointer"
                        onClick={() => {
                            useStore.getState().setNestedProperty(['uiStates', 'columnsNumber'], columnsNumber + 1);
                        }}
                    >
                        +
                    </div>
                </div>
                <div className="flex-2">
                    <div style={{ position: '', display: 'flex', right: 0, marginRight: '12px', marginTop: '12px' }}>
                        <div
                            onClick={() => {
                                setShowTemperatureValues(!areTemperatureValuesShown);
                            }}
                            className="mr-4p button-light-w-icon flex-center-center"
                        >
                            <div className="flex-center-center" style={{ height: '32px', width: '32px' }}>
                                <i
                                    className={areTemperatureValuesShown ? 'fa fa-check-square-o' : 'fa fa-square-o'}
                                ></i>
                            </div>
                            <span>{window.translate('Show temperatures')}</span>
                        </div>
                        <button
                            disabled={isFetchTemperatureDisabled}
                            onClick={async () => {
                                await fetchTemperatureValues('thermostat');
                            }}
                            className="button-primary ml-4p mr-4p"
                        >
                            {window.translate('Fetch temperature values')}
                        </button>

                        <button
                            disabled={isFetchTemperatureDisabled}
                            onClick={async () => {
                                await fetchTemperatureValues('wire');
                            }}
                            className="button-primary ml-4p mr-4p"
                        >
                            {window.translate('Fetch wire sensor values')}
                        </button>
                    </div>
                </div>
                <div></div>
            </div>

            {srcStatusDashboard.map((val, ind) => {
                if (ind < maxNumberOfItemsPerColumn) {
                    return (
                        <div key={ind} className="d-flex w-100">
                            {clmnArray.map((itm, i) => {
                                if (srcStatusDashboard[ind + i * maxNumberOfItemsPerColumn]) {
                                    return (
                                        <div
                                            key={ind + '_' + i}
                                            className="d-flex justify-content-between"
                                            style={{ flexBasis: `${100 / columnsNumber}%` }}
                                        >
                                            <HWLine
                                                key={ind}
                                                history={history}
                                                dashboardObj={{
                                                    ...srcStatusDashboard[ind + i * maxNumberOfItemsPerColumn],
                                                }}
                                                alarmInterpreter={alarmInterpreter}
                                                handleShowBasicOptions={handleShowBasicOptions}
                                                areTemperatureValuesShown={areTemperatureValuesShown}
                                                rooms={rooms}
                                            ></HWLine>
                                        </div>
                                    );
                                } else {
                                    return null;
                                }
                            })}
                        </div>
                    );
                } else {
                    return null;
                }
            })}
            {events && events.length > 0 ? (
                <div className={'p-1 mt-8p bg-warning text-dark p-2 shadow rounded'}>
                    <small>
                        {translate(
                            'There are active events whose controllers are not bound to any room. Close the events or bind the controller to a room.'
                        )}
                    </small>
                    <button
                        className="btn btn-sm btn-danger rounded ml-8p"
                        onClick={() => {
                            setModalContent(
                                <ConfirmModal
                                    actionForYes={async () => {
                                        await closeAllOldEvents(events);
                                        setEvents([]);
                                    }}
                                    content={translate('Please confirm')}
                                />,
                                translate('Confirm'),
                                false,
                                'modal-md'
                            );
                        }}
                    >
                        {translate('Close events')}
                    </button>
                </div>
            ) : null}
        </div>
    );
};

const HWLine = ({
    history,
    dashboardObj: i,
    alarmInterpreter,
    handleShowBasicOptions,
    areTemperatureValuesShown,
    rooms,
}) => {
    const inputInterpreter = alarmInterpreter?.inputInterpreter ? alarmInterpreter.inputInterpreter : [];
    const enabledInterpreters = inputInterpreter.filter((i) => i.enabled);
    // const roomInfoId = i?.roomInfoId;
    const lastReadoutTs = i.thReadoutTimestamp ? i.thReadoutTimestamp : null;

    const lastWireReadoutTs = i.wireReadoutTimestamp ? i.wireReadoutTimestamp : null;
    const room = rooms?.find((r) => r?.id === i?.roomInfoId);

    return (
        <small
            className="w-100 hw-room-tile"
            style={{ backgroundColor: 'white', margin: '1px', marginRight: '5px', border: '1px solid #ccc' }}
        >
            <div
                className="flex-center-center "
                // onClick={(e) => {e.stopPropagation()
                // history.push('/roomInfo/' + i.roomInfoId);
                // }}
                style={{ display: 'flex', justifyContent: 'space-between' }}
            >
                <div className="px-1 elipsis" style={{ flex: 1 }}>
                    {getRoomSRCStatusIconByLastSeenTimestamp(i.lastSeenTimestamp)}
                </div>
                <div className="px-1 elipsis" style={{ flex: 1 }}>
                    {room && room.remoteDeviceId && (room.remoteDeviceId2 || room.remoteDeviceId3)
                        ? i.roomName + ' (' + i.roomDevicePosition + ')'
                        : i.roomName}
                </div>
                {enabledInterpreters.map((ei, indx) => {
                    if (ei.name === 'cleaningServiceInput') {
                        return (
                            <div key={indx} className="px-1 elipsis" style={{ flex: 1 }}>
                                {i.lastCleaningDate === new Date().toISOString(true).substr(0, 10) ? (
                                    <i className="fa fa-female text-success" title={translate(ei.name)} />
                                ) : (
                                    <i className="fa fa-female" title={translate(ei.name)} />
                                )}
                            </div>
                        );
                    } else {
                        return HWIcon(i, ei.name, alarmInterpreter);
                    }
                })}

                {/*<div style={{ width: '30px' }}>{HWIcon(i, 'sosInput')}</div> //ovo nisu state inputi, već event inputi
<div style={{ width: '30px' }}>{HWIcon(i, 'doNotDisturbInput')}</div>
<div style={{ width: '30px' }}>{HWIcon(i, 'cleaningServiceInput')}</div>*/}

                <div className="px-1 elipsis" style={{ width: '80px' }}>
                    {i.roomMode}
                </div>

                <div
                    onClick={(e) => {
                        e.stopPropagation();
                        handleShowBasicOptions(i.roomInfoId, i.roomName, i.roomDeviceId);
                    }}
                    className="button-light mr-4p"
                >
                    {' '}
                    <i className="fa fa-key"></i>
                </div>
                <div
                    onClick={(e) => {
                        e.stopPropagation();
                        history.push('/roomEventHistory/' + i.roomInfoId + '/' + i.roomDeviceId);
                    }}
                    className="button-light"
                >
                    {' '}
                    <i className="fa fa-list"></i>
                </div>
            </div>
            {areTemperatureValuesShown ? (
                <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span className="ml-4p">
                            <span>
                                <strong>T1: </strong>
                                {i.lastTh1Readout}
                            </span>
                        </span>
                        <span className="">
                            <span>
                                <strong>T2: </strong>
                                {i.lastTh2Readout}
                            </span>
                        </span>
                        <span className="">
                            <span>
                                <strong>T3: </strong>
                                {i.lastTh3Readout}
                            </span>
                        </span>
                        <span className="mr-4p">
                            {lastReadoutTs ? `Last read: ${new Date(lastReadoutTs).toLocaleString()}` : null}
                        </span>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span className="ml-4p">
                            <span>
                                <strong>1WT1: </strong>
                                {i?.lastWire1Readout}
                            </span>
                        </span>
                        <span className="">
                            <span>
                                <strong>1WT2: </strong>
                                {i?.lastWire2Readout}
                            </span>
                        </span>
                        <span className="">
                            <span>
                                <strong>1WT3: </strong>
                                {i?.lastWire3Readout}
                            </span>
                        </span>

                        <span className="mr-4p">
                            {lastWireReadoutTs ? `Last read: ${new Date(lastWireReadoutTs).toLocaleString()}` : null}
                        </span>
                    </div>
                </div>
            ) : null}
        </small>
    );
};

const HWIcon = (dashboardObj, inputName, alarmInterpreter) => {
    let ioSRC = {};
    ioSRC['inputs'] = alarmInterpreter?.inputInterpreter
        ? alarmInterpreter.inputInterpreter.filter((i) => i.enabled)
        : [];
    ioSRC['outputs'] = [];

    ioSRC['inputMap'] = {};
    ioSRC.inputs.forEach((i) => {
        ioSRC['inputMap'][i.name] = i;
    });

    const desc = ioSRC.inputMap[inputName];
    if (inputName === 'floodAlarmInput' || inputName === 'cardHolderInput') {
        const isInputClosed = dashboardObj.inputs[inputName] === 0; //inputi su zatvoreni ako postoji i ako je false!!!
        //alarm se pali na true state
        const iconStyle = isInputClosed
            ? `${desc?.icon} ${desc?.falseStateAnimationBlink === 'true' ? ' animated infinite flash ' : ''}`
            : `${desc?.icon} ${desc?.trueStateAnimationBlink === 'true' ? ' animated infinite flash ' : ''}`;
        if (desc && desc.enabled) {
            return (
                <div key={inputName} className="px-1 elipsis" style={{ flex: 1 }}>
                    <i
                        title={translate(inputName)}
                        className={iconStyle}
                        style={{ color: isInputClosed ? desc?.falseStateIconColor : desc?.trueStateIconColor }}
                    />
                </div>
            );
        } else {
            return null;
        }
    } else {
        const isInputClosed = dashboardObj.inputs[inputName] === 0; //inputi su zatvoreni ako postoji i ako je false!!!
        //alarm se pali na false state
        const iconStyle = !isInputClosed
            ? `${desc?.icon} ${desc?.falseStateAnimationBlink === 'true' ? ' animated infinite flash ' : ''}`
            : desc?.icon;
        if (desc && desc.enabled) {
            return (
                <div key={inputName} className="px-1 elipsis" style={{ flex: 1 }}>
                    <i
                        title={translate(inputName)}
                        className={iconStyle}
                        style={{ color: !isInputClosed ? desc?.falseStateIconColor : desc?.trueStateIconColor }}
                    />
                </div>
            );
        } else {
            return null;
        }
    }
};

export default HwRoomView;
