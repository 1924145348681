import React from 'react';
import PropTypes from 'prop-types';

import Track from './Track';

const Tracks = ({ time, tracks, clickElement, onDragStart, onDrop }) => (
    <div className="rt-tracks">
        {tracks.map(({ id, elements, isOpen, tracks: children , room}) => (
            <Track
                onDragStart={onDragStart}
                onDrop={onDrop}
                key={id}
                time={time}
                elements={elements}
                isOpen={isOpen}
                tracks={children}
                clickElement={clickElement}
                room={room}
            />
        ))}
    </div>
);

Tracks.propTypes = {
    time: PropTypes.shape({}).isRequired,
    tracks: PropTypes.arrayOf(PropTypes.shape({})),
    clickElement: PropTypes.func,
};

export default Tracks;
