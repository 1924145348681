import React from 'react';
import PropTypes from 'prop-types';

import Header from './Header';
import Body from './Body';

const Sidebar = ({ timebar, tracks, toggleTrackOpen, sticky, clickTrackButton, trackButtonIcon, headerTopOffset }) => (
    <div className="rt-sidebar">
        <Header headerTopOffset={headerTopOffset} timebar={timebar} sticky={sticky} />
        <Body
            headerTopOffset={headerTopOffset}
            tracks={tracks}
            toggleTrackOpen={toggleTrackOpen}
            clickTrackButton={clickTrackButton}
            trackButtonIcon={trackButtonIcon}
        />
    </div>
);

Sidebar.propTypes = {
    timebar: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            title: PropTypes.string,
        }).isRequired
    ).isRequired,
    tracks: PropTypes.arrayOf(PropTypes.shape({})),
    toggleTrackOpen: PropTypes.func,
    sticky: PropTypes.shape({}),
    clickTrackButton: PropTypes.func,
    trackButtonIcon: PropTypes.element,
};

export default Sidebar;