import React, { Component } from 'react';
import { fileUpload } from '../../data/restWrapper';
import './style.css';
import {isFeatureEnabled} from "../FeatureFlag"

class FileUpload extends Component {
    async onChangeHandler(e) {
        try {
            this.props.setFileUploading(true)
            let fileInfo = await fileUpload('/uploadDocument', e.target.files[0]);
            if (this.props.onFileUploaded) {
                this.props.onFileUploaded(fileInfo);
            }
            this.props.setFileUploading(false)
        } catch (err) {
            this.props.setFileUploading(false)
            console.warn(err); 
        }
    }
    render() {

        if(!isFeatureEnabled('fileUploads')){
            return null;
        }

        let uploadButtonStyle = this.props.uploadButtonStyle ? this.props.uploadButtonStyle : null;
        let uploadButtonText = this.props.uploadButtonText ? (
            this.props.uploadButtonText
        ) : (
            <span>
                <i className="fa fa-upload" aria-hidden="true"></i>
                {window.translate("Choose a file")}
            </span>
        );
        return (
            <React.Fragment>
                <input
                    id="file"
                    className="inputfile"
                    type="file"
                    name="file"
                    onChange={this.onChangeHandler.bind(this)}
                />
                <label className={uploadButtonStyle+" uppercase"} htmlFor="file">
                    {uploadButtonText}
                </label>
            </React.Fragment>
        );
    }
}

export default FileUpload;
