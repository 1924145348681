import { MinibarLogWithIncludedModels } from '@common/modelDefinition';
import { getMinibarConsumption } from './api';
import moment from 'moment';
//@ts-ignore
import _ from 'lodash';

export interface ConsumptionModel extends MinibarLogWithIncludedModels {
    date: string;
}
export interface MinibarLogsGroupByDate {
    [key: string]: ConsumptionModel[];
}

export const getTodaysRoomConsumption = async (reservationId: number): Promise<MinibarLogsGroupByDate> => {
    const todaysRoomMinibarLogs: MinibarLogWithIncludedModels[] = await getMinibarConsumption(reservationId);
    const minibarData: MinibarLogsGroupByDate = getMinibarData(todaysRoomMinibarLogs);
    return minibarData;
};

export const getMinibarData = (
    minibarLogs: MinibarLogWithIncludedModels[],
): MinibarLogsGroupByDate => {
    let consumptionLogs =
        minibarLogs &&
        minibarLogs.map((ml: MinibarLogWithIncludedModels) => {
            //Ignore zbog definiranog modela MinibarLogModel, kojemu createdAt može biti undefined, null, mi smo ovdje dohvatili postojeće logove koji moraju imati createdAt, možda nekad zamijeniti sa recordedAt
            const consumptionLog: ConsumptionModel = {
                ...ml,
                //@ts-ignore
                date: moment(ml.createdAt).format('ll'),
            };
            return consumptionLog;
        });

    // if (filter === true) {
    //     consumptionLogs =
    //         consumptionLogs && consumptionLogs.filter((cl: MinibarLogWithIncludedModels) => !cl.InvoiceItem);
    // }

    const minibarData: MinibarLogsGroupByDate = _.groupBy(consumptionLogs, 'date');
    return minibarData;
};
