import React, { useState, useEffect } from 'react';
import translate from '@data/translations';
import FeatureFlag from '@components/FeatureFlag';
import { ReactComponent as MoreOptions } from '../assets/custom/icons/more_options.svg';
import PermissionCheck from './PermissionCheck';

interface Points {
    x: number;
    y: number;
}

export interface Options {
    label: string;
    flag?: string;
    permission?: string;
    icon?: any;
}

interface SelectProps {
    options: Options[];
    position: number;
    onSelect: Function;
    contextMenuIndex: number | null; //Nužan za right click context menu, nužan za selected row css, šalje se uvijek
    setContextMenuIndex: Function; //Nužan za right click context menu, nužan za selected row css, šalje se uvijek
    // Ispod se šalje samo ako želimo omogućiti right click context menu
    points?: Points;
    setPoints?: Function;
    style?: any;
    tooltip?: string;
    pointer?: any;
}
const ContextMenu = (props: SelectProps) => {
    const {
        options,
        position,
        onSelect,
        points,
        contextMenuIndex,
        setContextMenuIndex,
        setPoints,
        style,
        tooltip,
        pointer,
    } = props;
    const [targetUl, setTargetUl] = useState<number | null>(null);
    const selectedRef: any = React.useRef(null);

    useEffect(() => {
        // Granična ponašanja context menua
        const load = () => {
            if (targetUl !== null) {
                const uList: any = Array.from(
                    document.getElementsByClassName('context-menu-options') as HTMLCollectionOf<HTMLElement>
                )[targetUl];
                const subMenuBound = uList?.getBoundingClientRect();
                const headerElement: any = document.getElementById('default');
                const headerElementHeight = headerElement?.getBoundingClientRect().height;
                const { innerHeight: height } = window;
                const subMenuY = subMenuBound?.y;
                const subMenuHeight = subMenuBound?.height;

                if (subMenuHeight + subMenuY > height) {
                    const marginBlock = (subMenuHeight + headerElementHeight + 4) * -1;
                    uList.style.marginBlock = `${marginBlock}px`;
                    // assign a class to the sub-menu to
                }
                document.addEventListener('mousedown', (event: any) => {
                    if (
                        !headerElement.contains(event.target) &&
                        selectedRef.current &&
                        !selectedRef.current.contains(event.target) &&
                        event.target.id !== 'context-menu-item' &&
                        event.target.parentElement.id !== 'context-menu-item'
                    ) {
                        uList.style.display = 'none';
                        setContextMenuIndex(null);
                        if (setPoints) {
                            setPoints(null);
                        }
                    }
                });
                return () => {
                    // Unbind the event listener on clean up
                    document.removeEventListener('mousedown', (event: any) => {
                        if (!headerElement.isEqualNode(event.target)) {
                            uList.style.disply = 'none';
                            setContextMenuIndex(null);
                            if (setPoints) {
                                setPoints(null);
                            }
                        }
                    });
                };
            }
        };

        load();
    }, [targetUl, setContextMenuIndex, setPoints, selectedRef]);

    useEffect(() => {
        // Right click context menu
        const load = () => {
            if (contextMenuIndex !== null && contextMenuIndex !== undefined && points) {
                const elem: any = document.getElementById('context-menu');
                const distance = Math.floor(Math.sqrt(Math.pow(points.x - (elem.offsetLeft + elem.clientWidth), 2)));
                const _targetUl = Array.from(
                    document.getElementsByClassName('context-menu-options') as HTMLCollectionOf<HTMLElement>
                )[contextMenuIndex];
                if (_targetUl) {
                    _targetUl.style.display = 'inline-block';
                    const right = distance - _targetUl.clientWidth > 0 ? distance - _targetUl.clientWidth : distance;
                    // a.style.top = `${points.top}px`
                    _targetUl.style.right = `${right}px`;
                    setTargetUl(contextMenuIndex); //Aktiviraj useEffect koji regulira granična ponašanja context menua
                }
            }
        };
        load();
    }, [contextMenuIndex, points]);
    return (
        <div
            id="context-menu"
            ref={selectedRef}
            className="context-menu"
            onClick={(e) => {
                e.stopPropagation();
                const allUls = Array.from(
                    document.getElementsByClassName('context-menu-options') as HTMLCollectionOf<HTMLElement>
                );
                allUls.forEach((a, key) => {
                    if (key !== position) {
                        a.style.display = 'none';
                    } else {
                        if (a.style.display === 'inline-block') {
                            // Već je bio odabran
                            a.style.display = 'none';
                            setTargetUl(null);
                            setContextMenuIndex(null); // Deaktiviraj da je row izabran i enable hover na ostalima
                            if (setPoints) {
                                setPoints(null);
                            }
                        } else {
                            a.style.display = 'inline-block';
                            a.style.top = '100%';
                            a.style.right = '4px';
                            setTargetUl(key); //Aktiviraj useEffect koji regulira granična ponašanja context menua
                            setContextMenuIndex(key); // Aktiviraj da je row izabran i disable hover na ostalima
                            if (setPoints) {
                                setPoints(null); // Poništi right click context menu, tj. ne ulazi u useEffect koji regulira right click event
                            }
                        }
                    }
                });
            }}
        >
            <div id="default" className="button-white-w-icon-only" title={tooltip}>
                {pointer ? pointer : <MoreOptions />}
            </div>
            {options.length > 0 ? (
                <ul className="context-menu-options" style={style ? style : {}}>
                    <li id="hide">{pointer ? pointer : <MoreOptions />}</li>
                    {options.map((o, index) => {
                        const iconEl = o.icon ? o.icon : <svg id="context-menu-item" width="32" height="32"></svg>;
                        if (o) {
                            if (o.flag) {
                                return (
                                    <FeatureFlag key={index} flag={o.flag}>
                                        {o.permission ? (
                                            <PermissionCheck name={o.permission}>
                                                <li
                                                    id="context-menu-item"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        onSelect(o.label);
                                                    }}
                                                >
                                                    <span id="context-menu-item">{iconEl}</span> {translate(o.label)}
                                                </li>
                                            </PermissionCheck>
                                        ) : (
                                            <li
                                                id="context-menu-item"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    onSelect(o.label);
                                                }}
                                            >
                                                <span id="context-menu-item">{iconEl}</span> {translate(o.label)}
                                            </li>
                                        )}
                                    </FeatureFlag>
                                );
                            } else {
                                return o.permission ? (
                                    <PermissionCheck key={index} name={o.permission}>
                                        <li
                                            id="context-menu-item"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                onSelect(o.label);
                                            }}
                                        >
                                            <span id="context-menu-item">{iconEl}</span> {translate(o.label)}
                                        </li>
                                    </PermissionCheck>
                                ) : (
                                    <li
                                        id="context-menu-item"
                                        key={index}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            onSelect(o.label);
                                        }}
                                    >
                                        <span id="context-menu-item">{iconEl}</span> {translate(o.label)}
                                    </li>
                                );
                            }
                        } else {
                            return null;
                        }
                    })}
                </ul>
            ) : null}
        </div>
    );
};

export default ContextMenu;
