import React from 'react';
import "./tooltip.css"

function Tooltip(props){
    const {style = {}, children, message} = props;

    return <div className="tooltip2 d-inline pointer">{children}
        <span style={{zIndex: 1111, ...style }} className="tooltiptext2 mr-2 text-white bg-dark">{message}</span>
        </div>;
}

export default Tooltip;
