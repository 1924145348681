import * as React from 'react';
import { translate } from '@data/translations';
import BasicSRCSettings from '../../roomInfo/components/basicSRCSettingsModal';
import { setModalContent, closeModal } from '@components/modal';
import getRoomSRCStatusIcon, { getRoomSRCStatus } from '@data/controllers/SRCStatus';
import ShowAvailableRoomsForReservationModal from './ChangeRoomModal';
import ConfirmButtons from '@components/buttons/confirmButtons';
import PermissionCheck from '@components/PermissionCheck';
import { Reservation, ReservationController } from '../resController';
import { ReservationStatus, ReservationInvoicedStatus, ReservationModel } from '@common/modelDefinition';

interface ReservationToolbarProps {
    history: any;
    reservation: Reservation;
    location?: any;
    reservations: ReservationModel[];
}

interface BasicSRCSettingsButtonProps {
    room: any;
    reservation: Reservation;
}

export function BasicSRCSettingsButton(props: BasicSRCSettingsButtonProps) {
    const { reservation, room } = props;

    const handleShowBasicOptions = (remoteDeviceId: string) => {
        const roomName = room.name;
        const basicSRCSettings = (
            <BasicSRCSettings
                roomInfoId={room.id}
                key={room.id}
                reservation={reservation}
                remoteDeviceId={remoteDeviceId}
            />
        );
        setModalContent(
            basicSRCSettings,
            <span>
                {translate('Room settings') + ' - '} <b>{roomName}</b>
            </span>,
            true,
            'modal-xl'
        );
    };

    if (room === undefined) {
        return null;
    }

    const roomSRCStatusIcon = getRoomSRCStatusIcon(room.remoteDeviceId);
    const roomSRCStatusIcon2 = getRoomSRCStatusIcon(room.remoteDeviceId2);
    const roomSRCStatusIcon3 = getRoomSRCStatusIcon(room.remoteDeviceId3);

    const checkInToday = reservation
        ? new Date(reservation.checkInTimestamp).toISOString().substr(0, 10) === new Date().toISOString().substr(0, 10)
        : false;

    let disableSettingButton = true;
    let disableSettingButton2 = true;
    let disableSettingButton3 = true;

    if (
        reservation.statusEnum === ReservationStatus.checkedIn ||
        reservation.statusEnum === ReservationStatus.confirmed
    ) {
        disableSettingButton = false;
        disableSettingButton2 = false;
        disableSettingButton3 = false;
    } else if (checkInToday /*&& reservation.statusEnum === ReservationStatus.confirmed*/) {
        //if reservation status is confirmed and anounced check in is today (after they asked me to allow programing the room before guest checks in)
        disableSettingButton = false;
        disableSettingButton2 = false;
        disableSettingButton3 = false;
    }

    //disable if not connected

    if (getRoomSRCStatus(room.remoteDeviceId) >= 3) {
        disableSettingButton = true;
    }

    if (getRoomSRCStatus(room.remoteDeviceId2) >= 3) {
        disableSettingButton2 = true;
    }

    if (getRoomSRCStatus(room.remoteDeviceId3) >= 3) {
        disableSettingButton3 = true;
    }

    const disableButtonIfNoSRCClass =
        room.remoteDeviceId === null || roomSRCStatusIcon === null
            ? 'button-info  disabled mb-2 '
            : 'button-info   mb-2';

    const disableButtonIfNoSRCClass2 =
        room.remoteDeviceId2 === null || roomSRCStatusIcon2 === null
            ? 'button-info  disabled mb-2 '
            : 'button-info   mb-2';

    const disableButtonIfNoSRCClass3 =
        room.remoteDeviceId3 === null || roomSRCStatusIcon3 === null
            ? 'button-info  disabled mb-2 '
            : 'button-info   mb-2';

    const basicSettings =
        room.remoteDeviceId === null ? (
            <button
                disabled={disableSettingButton}
                title={translate('Room settings')}
                className={disableButtonIfNoSRCClass + ' mr-1'}
            >
                <i className="fa fa-cog" aria-hidden="true"></i>
            </button>
        ) : (
            <PermissionCheck name="roomInfo_roomSettings">
                <button
                    disabled={disableSettingButton}
                    title={translate('Room settings')}
                    onClick={()=> handleShowBasicOptions(room.remoteDeviceId)}
                    className={disableButtonIfNoSRCClass + ' mr-1'}
                >
                    <span className="d-inline-flex">{roomSRCStatusIcon} </span>
                    <i className="fa fa-cog" />
                </button>
            </PermissionCheck>
        );

    const basicSettings2 =
        room.remoteDeviceId2 === null ? (
            null
        ) : (
            <PermissionCheck name="roomInfo_roomSettings">
                <button
                    disabled={disableSettingButton2}
                    title={translate('Room settings')}
                    onClick={()=> handleShowBasicOptions(room.remoteDeviceId2)}
                    className={disableButtonIfNoSRCClass2 + ' mr-1'}
                >
                    <span className="d-inline-flex">{roomSRCStatusIcon2} </span>
                    <i className="fa fa-cog" />
                </button>
            </PermissionCheck>
        );

    const basicSettings3 =
        room.remoteDeviceId3 === null ? (
            null
        ) : (
            <PermissionCheck name="roomInfo_roomSettings">
                <button
                    disabled={disableSettingButton3}
                    title={translate('Room settings')}
                    onClick={()=> handleShowBasicOptions(room.remoteDeviceId3)}
                    className={disableButtonIfNoSRCClass3 + ' mr-1'}
                >
                    <span className="d-inline-flex">{roomSRCStatusIcon3} </span>
                    <i className="fa fa-cog" />
                </button>
            </PermissionCheck>
        );

    return <div className='row'>{basicSettings}{basicSettings2}{basicSettings3}</div>;
}

function ReservationToolbar(props: ReservationToolbarProps) {
    const { history, reservation, reservations } = props;
    const ctrl = new ReservationController();
    const groupResCover = ctrl.getGroupReservation();

    let isGroupInvoiceIssued = false;
    if (groupResCover && groupResCover.invoicedStatus !== ReservationInvoicedStatus.notPaid) {
        isGroupInvoiceIssued = true;
    }

    const room = reservation.RoomInfo;

    if (room === undefined) {
        return null;
    }

    const changeRoom = () => {
        setModalContent(
            <ShowAvailableRoomsForReservationModal reservation={{ ...reservation }} />,
            translate('Confirm ') + translate('change room'),
            false,
            'modal-lg'
        );
    };
    return (
        <div>
            <div className="display-flex">
                <button onClick={changeRoom} className="button-primary mr-1 mb-2 ">
                    {translate('CHANGE ROOM')}
                </button>

                {/* <button
                    disabled={!reservation.id}
                    title={translate('Detailed room events')}
                    onClick={() => {
                        history.push('/reservationDetails/' + reservation.id);
                    }}
                    type="button"
                    className="button-primary mr-1  mb-2 "
                >
                    <i className="fa fa-bar-chart" aria-hidden="true"></i>{' '}
                </button> */}

                <BasicSRCSettingsButton room={room} reservation={reservation} />

                {reservation.id &&
                !reservation.groupUuid &&
                !reservation.previousGroupUuid &&
                ![4, 5].includes(reservation.statusEnum) ? (
                    <button
                        className="button-warning mr-1  mb-2"
                        onClick={() => history.push('/groupInfos?reservationId=' + reservation.id)}
                    >
                        {translate('ATTACH TO GROUP RES.')}
                    </button>
                ) : null}

                {reservation.groupUuid && reservation.id && ![4, 5].includes(reservation.statusEnum) ? (
                    <button
                        disabled={isGroupInvoiceIssued}
                        onClick={() =>
                            setModalContent(
                                <div className="container-fluid">
                                    <div className="text-center">
                                        {translate(
                                            'Do you really want to remove this reservation from group? Please confirm and reservation will become standalone!'
                                        )}
                                        <hr />
                                    </div>
                                    <div className="text-center">
                                        <ConfirmButtons
                                            onConfirm={async () => {
                                                await ctrl.removeFromGroupRes();
                                                closeModal();
                                            }}
                                            onCancel={() => {
                                                closeModal();
                                            }}
                                        />
                                    </div>
                                </div>,
                                translate('Remove from group'), // header
                                false, // showCloseButton
                                'modal-md'
                            )
                        }
                        className="button-warning mr-1 text-uppercase  mb-2"
                    >
                        {translate('Remove from group')}
                    </button>
                ) : null}

                {reservation.groupUuid &&
                reservation.id &&
                reservations?.every((r) => r.statusEnum === 1 && !isGroupInvoiceIssued) ? (
                    <button
                        disabled={isGroupInvoiceIssued}
                        onClick={() =>
                            setModalContent(
                                <div className="container-fluid">
                                    <div className="text-center">
                                        {translate('Do you really want to cancel all this reservations?')}
                                        <hr />
                                    </div>
                                    <div className="text-center">
                                        <ConfirmButtons
                                            onConfirm={async () => {
                                                for (let i = 0; i < reservations.length; i++) {
                                                    if (reservations[i]) {
                                                        ctrl.setActiveReservationIndex(i);
                                                        let res = { ...reservations[i] };
                                                        res.statusEnum = ReservationStatus.canceled;
                                                        ctrl.updateActiveReservation(res as ReservationModel);
                                                    }
                                                }
                                                closeModal();
                                            }}
                                            onCancel={() => {
                                                closeModal();
                                            }}
                                        />
                                    </div>
                                </div>,
                                translate('Cancel all reservations'), // header
                                false, // showCloseButton
                                'modal-md'
                            )
                        }
                        className="button-warning mr-1  mb-2"
                    >
                        {translate('CANCEL ALL')}
                    </button>
                ) : null}
                {reservation.id && reservation.providerReservation ? (
                    <small className="text-danger">{translate('PROVIDER RESERVATION')}</small>
                ) : null}
            </div>
        </div>
    );
}

export default ReservationToolbar;
