import React from 'react';
import './printstyle.css';

interface PrintViewProps {
    documentHeader: any;
    documentFooter: any;
    headerHeight: number;
    footerHeight: number;
    items: any;
    pageBreak?: boolean;
}
const PrintView = (props: PrintViewProps) => {
    const {
        documentHeader,
        documentFooter = <div style={{ minHeight: '12px' }}></div>,
        headerHeight,
        footerHeight,
        items,
        pageBreak,
    } = props;

    return (
        <div className='' style={pageBreak ? { pageBreakBefore: 'always' } : {}} >
            <div className="print-page-header">{documentHeader}</div>
            <div className="print-page-footer">{documentFooter}</div>
            <table className="w-100">
                <thead className=''>
                    <tr>
                        <td>
                            <div style={{ height: `${headerHeight ? headerHeight : 100}px`}}></div>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <div className="print-page bg-white pr-1 pl-1">{items}</div>
                        </td>
                    </tr>
                </tbody>
                <tfoot className=''>
                    <tr>
                        <td>
                            <div style={{ height: `${footerHeight ? footerHeight : 60}px` }}></div>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    );
};

export default PrintView;
