import React from 'react';
//@ts-ignore
import _ from 'lodash';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import 'rc-time-picker/assets/index.css';
//@ts-ignore
import TimePicker from 'rc-time-picker';
import rest from '../../data/restWrapper';
import moment from 'moment';
//@ts-ignore
import { formatDate, parseDate } from 'react-day-picker/moment';
//import FeatureFlag from '@components/FeatureFlag';
import PermissionCheck from '@components/PermissionCheck';
import YearMonthForm from '@components/yearDayCalendarSelect';
import translate from '@data/translations';
import useStore from '@data/state/zustand';

const AccessCodes = (props: any) => {
    const remoteDeviceId = props.remoteDeviceId || props.match.params.remoteDeviceId;
    const [_fromMonth, setFromMonth] = React.useState<Date>(new Date());
    const [accessCodes, setAccessCodes] = React.useState<any>(props.roomSettings.accessCodes);
    const [showBody, setShowBody] = React.useState<boolean>(true);
    const [modified, setModified] = React.useState<any>({
        user1: false,
        user2: false,
        user3: false,
        manager: false,
        security: false,
        maid1: false,
        maid2: false,
        maid3: false,
    });

    React.useEffect(() => {
        const loadData = async () => {
            if (props.roomSettings.accessCodes) {
                setAccessCodes(props.roomSettings.accessCodes);
            }
        };
        loadData().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, [props.roomSettings, props.remoteDeviceId]);

    const getRandomMilisecondsToAddFromRangeOfMinutes = (min: number, max: number) => {
        const otherSettings = useStore.getState().otherSettings;
        // let arrOfInts = []
        let maxInMiliseconds = max * 60 * 1000;
        let randomMilisecondsToAdd = otherSettings?.testModeForSimultaneouslyCardCodeExpiration
            ? 0
            : Math.floor(Math.random() * (maxInMiliseconds - min + 1)) + min;
        // for (let i = 0; i <= maxInMiliseconds; i++) {
        //     arrOfInts.push(Math.floor(Math.random() * (maxInMiliseconds - min + 1)) + min)
        // }
        // let finalNum = arrOfInts[Date.now() % (max + 1)]
        return randomMilisecondsToAdd;
    };

    const roundToNearest15 = (date = new Date()) => {
        const minutes = 15;
        const ms = 1000 * 60 * minutes;
        return new Date(Math.floor(date.getTime() / ms) * ms).getTime();
    };

    const onCodeChange = (type: string, e: any) => {
        let _accessCodes = { ...accessCodes };
        let _modified = modified;
        _modified[type] = true;

        _accessCodes[type].code = e.target.value ? parseInt(e.target.value, 10) : 0;
        setAccessCodes(_accessCodes);
        setModified(_modified);
    };

    const handleTimeChange = (type: string, e: any) => {
        let _accessCodes = { ...accessCodes };
        let _modified = modified;
        _modified[type] = true;
        let milisecondsToAdd = getRandomMilisecondsToAddFromRangeOfMinutes(0, 60);

        _accessCodes[type].validToTimestamp = e.valueOf() + milisecondsToAdd;
        setAccessCodes(_accessCodes);
        setModified(_modified);
    };

    const handleDateChange = (type: string, e: any) => {
        let _accessCodes = { ...accessCodes };
        let _modified = modified;
        _modified[type] = true;

        let milisecondsToAdd = getRandomMilisecondsToAddFromRangeOfMinutes(0, 60);
        _accessCodes[type].validToTimestamp = e.getTime() + milisecondsToAdd;
        setAccessCodes(_accessCodes);
        setModified(_modified);
    };

    const handleDateTimeSet = (type: string, targetTimestamp: any) => {
        let _accessCodes = { ...accessCodes };
        let _modified = modified;
        _modified[type] = true;

        _accessCodes[type].validToTimestamp = targetTimestamp;
        setAccessCodes(_accessCodes);
        setModified(_modified);
    };

    const onCardCodeSave = async (cardCodeType: string) => {
        try {
            let cardCodeToSave = accessCodes[cardCodeType];
            let payload = {
                code: cardCodeToSave.code,
                validToTimestamp: cardCodeToSave.validToTimestamp,
                cardCodeType: cardCodeType,
                reservationId: props.reservation ? props.reservation.id : null,
            };

            if (props.onSaveHandler) {
                let accessCodes = props.SRCTemplateData.accessCodes;
                accessCodes[cardCodeType] = cardCodeToSave;
                props.onSaveHandler('accessCodes', accessCodes);
            } else {
                if (props.showOnlyOneSRC) {
                    if (props.remoteDeviceId1) {
                        await rest('/src/setCardCodeWithValidToTime/' + props.remoteDeviceId1, 'POST', payload);
                    }
                    if (props.remoteDeviceId2) {
                        await rest('/src/setCardCodeWithValidToTime/' + props.remoteDeviceId2, 'POST', payload);
                    }
                    if (props.remoteDeviceId3) {
                        await rest('/src/setCardCodeWithValidToTime/' + props.remoteDeviceId3, 'POST', payload);
                    }
                } else {
                    await rest('/src/setCardCodeWithValidToTime/' + remoteDeviceId, 'POST', payload);
                }
                let _modified = modified;
                _modified[cardCodeType] = false;
                setModified(_modified);
            }
        } catch (err) {
            console.warn(err);
        }
    };

    const getAcceptReservationExpButton = (userType: any) => {
        if (props.reservation) {
            let acceptReservationExpirationButton = null;
            let reservationTime = moment(props.reservation.checkOutTimestamp);
            let userTime = moment(accessCodes[userType].validToTimestamp);

            if (accessCodes[userType].code !== 0 && Math.abs(userTime.diff(reservationTime, 'hours')) >= 1) {
                acceptReservationExpirationButton = (
                    <button
                        onClick={() => {
                            handleDateTimeSet(userType, moment(reservationTime).valueOf());
                        }}
                        className="btn btn-sm btn-warning mt-2 mr-2"
                    >
                        <small>{translate('ACCEPT CURRENT RESERVATION EXP.')}</small>
                    </button>
                );
            }
            return acceptReservationExpirationButton;
        } else {
            return null;
        }
    };

    const discardCode = async (cardCodeType: any) => {
        try {
            let payload = {
                code: 0,
                validToTimestamp: 0,
                cardCodeType,
                reservationId: props.reservation ? Number(props.reservation.id) : null,
            };
            await rest('/src/setCardCodeWithValidToTime/' + remoteDeviceId, 'POST', payload);

            let _accessCodes = { ...accessCodes };
            _accessCodes[cardCodeType].code = 0;
            setAccessCodes(_accessCodes);
        } catch (err) {
            console.log(err);
        }
    };

    const handleYearMonthChange = (e: any) => {
        const selectedDate = new Date(e);
    
        let previousMonth = new Date(selectedDate.setMonth(selectedDate.getMonth() - 1));
        setFromMonth(previousMonth); // First, set the month - 1
        
        setTimeout(() => {
            setFromMonth(e); 
        }, 0); 
    };

    const dateFormat = props.otherSettings?.dateFormat ? props.otherSettings.dateFormat : 'DD/MM/YYYY';
    let controlls: any = [];

    _.forIn(accessCodes, (value: any, key: any) => {
        let acceptReservationExpirationButton = getAcceptReservationExpButton(key);

        let template = (
            <div key={key} className="col-md-4 pull-left">
                <div className="mt-2 bg-light p-2 border border-info">
                    <h3 className="text-center">
                        {key === 'manager' || key === 'security' ? translate('Access only') : key}
                    </h3>
                    {translate('Access code:')}&ensp;
                    <div className="row mb-1">
                        <div className="col-8">
                            <input
                                onChange={(e) => onCodeChange(key, e)}
                                type="text"
                                className="input-text form-control form-control-sm"
                                value={value.code}
                            ></input>
                        </div>
                        <div className="col-4 text-left">
                            {accessCodes[key].code !== 0 ? (
                                <button onClick={() => discardCode(key)} className="btn btn-sm btn-danger pull-left">
                                    <i className="fa fa-eraser" />
                                </button>
                            ) : null}
                        </div>
                    </div>
                    {translate('Expires')}:&ensp; <small>{moment(value.validToTimestamp).format(dateFormat)}</small>
                    <div className="row">
                        <div className="col-8 pr-0" style={{ display: 'flex' }}>
                            <PermissionCheck name="basic_src_update_access_code_settings">
                                <div>
                                    <DayPickerInput
                                        placeholder={dateFormat}
                                        inputProps={{
                                            className: 'form-control form-control-sm mt-1 mt-md-0 input-text ',
                                            readOnly: true,
                                        }}
                                        dayPickerProps={{
                                            todayButton: translate('Today'),
                                            className: 'text-center',
                                            // fromMonth:_fromMonth,
                                            month: _fromMonth,
                                            firstDayOfWeek: 1,
                                            //@ts-ignore
                                            captionElement: ({ date }) => (
                                                <YearMonthForm
                                                    date={date}
                                                    onChange={(e: any) => handleYearMonthChange(e)}
                                                    minYear={1900}
                                                    // maxYear={1900}
                                                />
                                            ),
                                        }}
                                        value={new Date(value.validToTimestamp)}
                                        format={dateFormat}
                                        formatDate={formatDate}
                                        parseDate={parseDate}
                                        // className="input-text "
                                        onDayChange={(e: any) => handleDateChange(key, e)}
                                    />
                                </div>
                                <div>
                                    <div
                                        title={translate('Jump to today')}
                                        onClick={() => handleDateChange(key, new Date())}
                                        className="button-primary ml-4p"
                                    >
                                        <i className="fa fa-calendar-times-o"></i>
                                    </div>
                                </div>
                            </PermissionCheck>
                        </div>
                        <div className="col-4 pl-0">
                            <TimePicker
                                id={key}
                                showMinute={true}
                                allowEmpty={false}
                                style={{ background: 'transparent', textAlign: 'center' }}
                                minuteStep={15}
                                showSecond={false}
                                value={moment(roundToNearest15(new Date(value.validToTimestamp))).clone()}
                                className="ml-2 "
                                onChange={(e: any) => handleTimeChange(key, e)}
                            />
                        </div>
                    </div>
                    <hr />
                    <div className="text-center">
                        {key === 'guest1' || key === 'guest2' || key === 'guest3'
                            ? acceptReservationExpirationButton
                            : null}

                        <button
                            disabled={!modified[key]}
                            onClick={() => onCardCodeSave(key)}
                            className="btn btn-sm btn-info mt-2"
                        >
                            {translate('Update')}
                        </button>
                    </div>
                </div>
            </div>
        );

        if (props.template) {
            if (key === 'maid1' || key === 'maid2' || key === 'maid3' || key === 'manager' || key === 'security') {
                controlls.push(template);
            } else {
            }
        } else {
            if (
                key === 'guest1' ||
                key === 'guest2' ||
                key === 'guest3' ||
                key === 'maid1' ||
                key === 'maid2' ||
                key === 'maid3' ||
                key === 'manager' ||
                key === 'security'
            ) {
                controlls.push(template);
            } else {
            }
        }
    });

    return (
        <div className="card mt-2">
            <div
                onClick={() => {
                    setShowBody(!showBody);
                }}
                className="card-header text-light bg-dark text-center pointer"
            >
                {translate('ACCESS CODES')}
            </div>

            <div className="clear mb-2">{controlls}</div>
        </div>
    );
};

export default AccessCodes;
