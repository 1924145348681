import React, { Component } from 'react';
import './modal.css';
import { ReactComponent as Close } from '../../assets/custom/icons/ex.svg';

class InlineModal extends Component {
    render() {
        let { show = false, width = 'md', setShow } = this.props;
        let style = show ? { display: 'block', overflowY: 'auto', paddingTop: '40px' } : { display: 'none' };
        return (
            <div id="modal-overlay" style={style}>
                <div className="modal fade show" tabIndex="-1" style={style}>
                    <div className={'modal-dialog modal-' + width}>
                        <div className="modal-content">
                            {this.props.header ? (
                                <div className="modal-header d-flex justify-content-between">
                                    <div>
                                        <h5 className="modal-title">{this.props.header}</h5>
                                    </div>

                                    <button className="close pointer" onClick={() => setShow(null)}>
                                    {/* <span aria-hidden="true">&times;</span> */}
                                    <Close></Close>
                                    </button>
                                </div>
                            ) : null}

                            <div className="modal-body">{this.props.children}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default InlineModal;
