import React, { useEffect } from 'react';
import { ReactComponent as ArrowTriangleUp } from '../assets/custom/icons/arrow_triangle_up.svg';
import { ReactComponent as ArrowTriangleDown } from '../assets/custom/icons/arrow_triangle_down.svg';
import { ReactComponent as Search } from '../assets/custom/icons/search.svg';
import { ReactComponent as ChevronRight } from '../assets/custom/icons/chevron_right.svg';
import { ReactComponent as Remove } from '../assets/custom/icons/trash.svg';
import { ReactComponent as ChevronDown } from '../assets/custom/icons/chevron_down.svg';
import Image from '@components/image';
import CustomInput from '@components/custom/input';
import translate from '@data/translations';
import moment from 'moment';
import 'moment/min/locales.min';
import citizenshipCode from '@common/citizenshipCode.json';
import numbro from 'numbro';
//@ts-ignore
import { Link } from 'react-router-dom';
import countryCodes from '@common/countryCodes.json';
import {
    GLReportDocTypeName,
    RoomInfoModel,
    StockDocumentTypesEnum,
    TaxModel,
    TypeAndClassEnumText,
    TypeAndClassModel,
} from '@common/modelDefinition';
import RoomAttributes from './attributes';
import getRoomSRCStatusIcon, { getRoomSRCStatus, RoomStatus } from '@data/controllers/SRCStatus';
import { RoomReservationStatusColors, RoomReservationStatusText } from '@pages/householdNew/interfaces';
import useStore from '@data/state/zustand';
import { openDocument } from './documentTools/api';

export const getDocumentIconBasedOnFileType = (docInfo: any) => {
    let result = null;
    const _defaultFileIconPlaceholder = <i className="fa fa-file"></i>;

    switch (docInfo.type) {
        case 'image/jpeg':
        case 'image/png':
            result = <i className="fa fa-file-image-o"></i>;
            break;
        case 'application/json':
            result = <i className="fa fa-file-text-o"></i>;
            break;
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            result = <i className="fa fa-file-word-o"></i>;
            break;
        case 'application/pdf':
            result = <i className="fa fa-file-pdf-o"></i>;
            break;
        default:
            result = _defaultFileIconPlaceholder;
            break;
    }
    return result;
};
export interface HeaderItem {
    filterKey: string;
    headerTitle: string;
    label: any; //string | number | null | boolean,
    type: string;
    filterType?: string;
    bootstrapColumnClass?: string;
    cellWidth?: string;
    enumDescription?: any;
    exportId?: any;
    linkSchema?: string[];
    linkSubstitutions?: string;
    openInNewTab?: Function;
    linkText?: string;
    showRowsWithIds?: any[];
    action?: Function;
}

export const checkArrayEquality = (arr1: any, arr2: any) => {
    const areEqual = arr1.length === arr2.length && arr1.every((value: any, index: number) => value === arr2[index]);

    if (areEqual) {
        return true;
    } else {
        return false;
    }
};

const getTagsLogic = (arrayOfObjects: any[], tagCell: string) => {
    const tagsAll: (string | null)[] = [];

    arrayOfObjects.forEach((o) => {
        if (o[tagCell]) {
            const tagsArray = typeof o[tagCell] === 'string' ? o[tagCell].split(';') : o[tagCell];
            for (const tag of tagsArray) {
                if (!tagsAll.includes(tag)) {
                    tagsAll.push(tag);
                }
            }
        } else if (o[tagCell]) {
            if (!tagsAll.includes(null)) {
                tagsAll.push(null);
            }
        }
    });

    const result: QueryPropertyValue[] = [];

    for (const _tag of tagsAll) {
        const newObject = {} as QueryPropertyValue;
        //  newObject[_tag] = targetObjects;
        newObject.label = _tag;
        newObject.field = tagCell;
        newObject.value = _tag;
        newObject.type = 'text';
        if (tagsAll.length > 0) {
            result.push(newObject);
        }
    }

    return result;
};

const getRoomReservationStatusesLogic = (arrayOfObjects: any[], roomReservationStatusesCell: string) => {
    const roomStatusesAll: (string | null)[] = [];

    arrayOfObjects.forEach((o) => {
        if (o[roomReservationStatusesCell]) {
            const roomStatusesArray =
                typeof o[roomReservationStatusesCell] === 'string'
                    ? o[roomReservationStatusesCell].split(';')
                    : o[roomReservationStatusesCell];
            for (const roomStatus of roomStatusesArray) {
                if (!roomStatusesAll.includes(roomStatus)) {
                    roomStatusesAll.push(roomStatus);
                }
            }
        } else if (o[roomReservationStatusesCell]) {
            if (!roomStatusesAll.includes(null)) {
                roomStatusesAll.push(null);
            }
        }
    });

    const result: QueryPropertyValue[] = [];

    for (const _roomStatus of roomStatusesAll) {
        const newObject = {} as QueryPropertyValue;
        //  newObject[_roomStatus] = targetObjects;
        newObject.label = translate(RoomReservationStatusText[_roomStatus as keyof typeof RoomReservationStatusText]);
        newObject.field = roomReservationStatusesCell;
        newObject.value = _roomStatus;
        newObject.type = 'text';
        if (roomStatusesAll.length > 0) {
            result.push(newObject);
        }
    }

    return result;
};

const getCategoriesLogic = (arrayOfObjects: any[]) => {
    const categoryAll: (string | null)[] = [];

    arrayOfObjects.forEach((o) => {
        const { category } = o;

        if (category) {
            const categoryArray = category.split(';');
            for (const _category of categoryArray) {
                if (!categoryAll.includes(_category)) {
                    categoryAll.push(_category);
                }
            }
        } else if (category !== undefined) {
            if (!categoryAll.includes(null)) {
                categoryAll.push(null);
            }
        }
    });

    const result: QueryPropertyValue[] = [];

    for (const badge of categoryAll) {
        const newObject = {} as QueryPropertyValue;
        //  newObject[_tag] = targetObjects;
        newObject.label = badge;
        newObject.field = 'category';
        newObject.value = badge;
        newObject.type = 'stringArray';
        result.push(newObject);
    }

    return result;
};

const getReservationStatusLogic = (arrayOfObjects: any[]) => {
    const reservationStatusesAll: (string | null)[] = [];

    arrayOfObjects.forEach((o) => {
        const { params } = o;

        if (params) {
            const reservationStatusesArray = params.split(';');
            for (const _reservationStatus of reservationStatusesArray) {
                if (!reservationStatusesAll.includes(_reservationStatus)) {
                    reservationStatusesAll.push(_reservationStatus);
                }
            }
        } else if (params !== undefined) {
            if (!reservationStatusesAll.includes(null)) {
                reservationStatusesAll.push(null);
            }
        }
    });

    const result: QueryPropertyValue[] = [];

    for (const badge of reservationStatusesAll) {
        const newObject = {} as QueryPropertyValue;
        //  newObject[_tag] = targetObjects;
        newObject.label = badge;
        newObject.field = 'params';
        newObject.value = badge;
        newObject.type = 'stringArray';
        result.push(newObject);
    }

    return result;
};

const getRoomAtributesLogic = (arrayOfObjects: any[], attributes: any[]) => {
    const atributesAll: (number | null)[] = [];
    arrayOfObjects.forEach((o) => {
        const { roomAttributes } = o;
        const _roomAttributes = typeof roomAttributes === 'string' ? JSON.parse(roomAttributes) : roomAttributes;

        if (_roomAttributes) {
            for (const roomAttribute of _roomAttributes) {
                if (!atributesAll.includes(roomAttribute)) {
                    atributesAll.push(roomAttribute);
                }
            }
        } else if (_roomAttributes !== undefined) {
            if (!atributesAll.includes(null)) {
                atributesAll.push(null);
            }
        }
    });

    const result: QueryPropertyValue[] = [];

    for (const amenity of atributesAll) {
        const attr = attributes.find((at) => at.id === amenity);
        const newObject = {} as QueryPropertyValue;
        //  newObject[_tag] = targetObjects;
        newObject.label = translate(attr?.name);
        newObject.field = 'roomAttributes';
        newObject.value = amenity;
        newObject.type = '';
        result.push(newObject);
    }
    return result;
};

const getRoomStatusLogic = (arrayOfObjects: any[]) => {
    const roomStatusesAll: any[] = [];
    arrayOfObjects.forEach((o) => {
        const roomStatus = getRoomSRCStatus(o.remoteDeviceId);
        const roomStatus2 = getRoomSRCStatus(o.remoteDeviceId2);
        const roomStatus3 = getRoomSRCStatus(o.remoteDeviceId3);

        //const roomStatusIcon = getRoomSRCStatusIcon(o)
        if (roomStatus !== undefined) {
            if (!roomStatusesAll.includes(roomStatus)) {
                roomStatusesAll.push(roomStatus);
            }
        } else if (roomStatus !== undefined) {
            if (!roomStatusesAll.includes(null)) {
                roomStatusesAll.push(null);
            }
        }

        if (roomStatus2 !== undefined) {
            if (!roomStatusesAll.includes(roomStatus2)) {
                roomStatusesAll.push(roomStatus2);
            }
        } else if (roomStatus2 !== undefined) {
            if (!roomStatusesAll.includes(null)) {
                roomStatusesAll.push(null);
            }
        }

        if (roomStatus3 !== undefined) {
            if (!roomStatusesAll.includes(roomStatus3)) {
                roomStatusesAll.push(roomStatus3);
            }
        } else if (roomStatus3 !== undefined) {
            if (!roomStatusesAll.includes(null)) {
                roomStatusesAll.push(null);
            }
        }
    });

    const result: QueryPropertyValue[] = [];

    for (const _roomStatus of roomStatusesAll) {
        const newObject = {} as QueryPropertyValue;
        //  newObject[_tag] = targetObjects;
        newObject.label = translate(RoomStatus[_roomStatus]);
        newObject.field = 'remoteDeviceId';
        newObject.value = _roomStatus;
        newObject.type = 'roomStatus';
        result.push(newObject);
    }
    return result;
};

export interface GetQueryPropertyValueProps {
    arrayOfObjects: any[];
    headerItem: HeaderItem;
    locale?: string;
    exportData?: any;
    setExportData?: Function;
    selectExportFilter?: Function;
    companyInfo?: any; //treba napraviti
    history: any;
    taxes?: TaxModel[];
    rooms?: RoomInfoModel[];
    customersName?: CustomersOrCompaniesName[];
    companiesName?: CustomersOrCompaniesName[];
    srcProxyNames?: CustomersOrCompaniesName[];
    attributes?: any[];
    isPrimaryKey?: boolean;
    userNames?: userName[];
    typeAndClasses?: TypeAndClassModel[];
}

export const getQueryPropertyValue = (props: GetQueryPropertyValueProps) => {
    const {
        arrayOfObjects,
        headerItem,
        locale,
        exportData,
        setExportData,
        selectExportFilter,
        companyInfo,
        history,
        taxes,
        rooms,
        customersName,
        companiesName,
        srcProxyNames,
        attributes,
        isPrimaryKey,
        userNames,
        typeAndClasses,
    } = props;
    let result: QueryPropertyValue[] = [];
    const propertyName = headerItem.filterKey;
    if (
        propertyName !== 'tags' &&
        propertyName !== 'category' &&
        propertyName !== 'roomAttributes' &&
        propertyName !== 'remoteDeviceId' &&
        propertyName !== 'roomTagWhitelistCSV' &&
        propertyName !== 'whitelistTagCSV' &&
        propertyName !== 'params' &&
        propertyName !== 'roomStatuses'
    ) {
        for (const object of arrayOfObjects) {
            let tax = taxes?.find((t) => t.id === Number(object.taxId));
            let room = isPrimaryKey
                ? rooms?.find((r) => r.id === Number(object.id))
                : rooms?.find((r) => r.id === Number(object.roomInfoId));
            const customerNameObject = customersName?.find((c) => {
                if (object.customerId) {
                    if (c.id === object.customerId) {
                        return c;
                    } else {
                        return null;
                    }
                } else {
                    return null;
                }
            });
            const companyNameObject = companiesName?.find((c) => {
                if (object.companyId) {
                    if (c.id === object.companyId) {
                        return c;
                    } else {
                        return null;
                    }
                } else {
                    return null;
                }
            });
            const sryProxyNameObj = srcProxyNames?.find((s) => {
                if (object.srcProxyId) {
                    if (s.id === object.srcProxyId) {
                        return s;
                    } else {
                        return null;
                    }
                } else {
                    return null;
                }
            });
            const userNameObj = userNames?.find((u) => {
                if (object.uuid) {
                    if (u.uuid === object.uuid) {
                        return u;
                    } else {
                        return null;
                    }
                } else if (object.userAssigned) {
                    if (u.uuid === object.userAssigned) {
                        return u;
                    } else {
                        return null;
                    }
                } else if (object.userCreated) {
                    if (u.uuid === object.userCreated) {
                        return u;
                    } else {
                        return null;
                    }
                } else {
                    return null;
                }
            });
            const customerName = customerNameObject?.name;
            const companyName = companyNameObject?.name;
            const srcProxy = sryProxyNameObj?.name;
            const userName = userNameObj?.name;

            const getLabelObj = {
                headerItem,
                arrayItem: object,
                locale,
                exportData,
                setExportData,
                selectExportFilter,
                companyInfo, //treba napraviti
                history,
                tax,
                room,
                customerName,
                companyName,
                srcProxy,
                userName,
                typeAndClasses,
            };

            const objectWithEnumDescription = { ...object };
            objectWithEnumDescription[propertyName] = getLabel(getLabelObj);

            const propertyNameOfObject = objectWithEnumDescription[propertyName];

            const alreadyGrouped: any = result.find((r: any) => {
                if (r.label === propertyNameOfObject?.toString() || r.label === propertyNameOfObject) {
                    return r;
                } else {
                    return null;
                }
            });

            if (!alreadyGrouped) {
                if (
                    objectWithEnumDescription[propertyName] !== null &&
                    objectWithEnumDescription[propertyName] !== undefined
                ) {
                    const newObj: QueryPropertyValue = { field: '', label: '', value: '', type: '' };
                    newObj.label = propertyNameOfObject;
                    newObj.field = propertyName;
                    newObj.value = object[propertyName];
                    newObj.type = headerItem.type;

                    //   newObj[propertyNameOfObject] = [objectWithEnumDescription];
                    result.push(newObj);
                }
            } else {
                if (
                    objectWithEnumDescription[propertyName] !== null &&
                    objectWithEnumDescription[propertyName] !== undefined
                ) {
                    result = result.map((r: QueryPropertyValue) => {
                        if (r.label === propertyNameOfObject?.toString()) {
                            const newObj: any = { ...r };
                            newObj.value = object[propertyName];
                            newObj.field = propertyName;
                            newObj.label = propertyNameOfObject;
                            newObj.type = headerItem.type;
                            // newObj[propertyNameOfObject].push(objectWithEnumDescription);
                            return newObj;
                        } else {
                            return r;
                        }
                    });
                }
            }
        }
    } else {
        if (propertyName === 'tags') {
            result = getTagsLogic(arrayOfObjects, 'tags');
        } else if (propertyName === 'roomTagWhitelistCSV') {
            result = getTagsLogic(arrayOfObjects, 'roomTagWhitelistCSV');
        } else if (propertyName === 'whitelistTagCSV') {
            result = getTagsLogic(arrayOfObjects, 'whitelistTagCSV');
        } else if (propertyName === 'category') {
            result = getCategoriesLogic(arrayOfObjects);
        } else if (propertyName === 'remoteDeviceId') {
            result = getRoomStatusLogic(arrayOfObjects);
        } else if (propertyName === 'params') {
            result = getReservationStatusLogic(arrayOfObjects);
        } else if (propertyName === 'roomStatuses') {
            result = getRoomReservationStatusesLogic(arrayOfObjects, 'roomStatuses');
        } else {
            if (attributes) {
                result = getRoomAtributesLogic(arrayOfObjects, attributes);
            }
        }
    }
    return result;
};

export interface Filters {
    filterField: string;
    filterValues: (string | number | null | boolean)[];
    type: string;
}

export interface QueryPropertyValue {
    field: string;
    label: string | null;
    value: string | number | boolean | null;
    type: string;
}

interface TableContextMenuProps {
    children: any;
    fontSize: string; // vjerocatno nece trebat
    setShowContextMenu: Function;
}
export const TableContextMenu = (props: TableContextMenuProps) => {
    const { children, fontSize, setShowContextMenu } = props;

    useEffect(() => {
        const load = () => {
            const dropDownList: any = document.getElementById('table-context-menu-options');
            dropDownList.style.fontSize = fontSize;
            document.addEventListener('mousedown', (event: any) => {
                if (!event?.target?.classList?.contains('on-mouse-down-show') || !event?.target?.classList) {
                    setShowContextMenu(null);
                }
            });
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener('mousedown', (event: any) => {
                    if (!event?.target?.classList?.contains('on-mouse-down-show') || !event?.target?.classList) {
                        setShowContextMenu(null);
                    }
                });
            };
        };

        load();
    }, [setShowContextMenu, fontSize]);

    return (
        <div id="table-context-menu-options" className="table-context-menu-options">
            {children}
        </div>
    );
};

interface TableSortOptionsProps {
    sortByColumn: Function;
    setShowContextMenu: Function;
}

export const TableSortOptions = (props: TableSortOptionsProps) => {
    const { sortByColumn, setShowContextMenu } = props;
    return (
        <React.Fragment>
            <div
                className="on-mouse-down-show"
                onClick={() => {
                    sortByColumn('asc');
                    setShowContextMenu(null);
                }}
            >
                <span className="on-mouse-down-show">
                    <ArrowTriangleUp />
                </span>
                <span className="on-mouse-down-show">Sort A-Z</span>
            </div>
            <div
                className="on-mouse-down-show"
                onClick={() => {
                    sortByColumn('desc');
                    setShowContextMenu(null);
                }}
            >
                <span className="on-mouse-down-show">
                    <ArrowTriangleDown />
                </span>
                <span className="on-mouse-down-show">Sort Z-A</span>
            </div>
        </React.Fragment>
    );
};

interface TableContextMenuItemListProps {
    propertyValueHeaders: QueryPropertyValue[];
    filters: Filters[];
    setFilters?: Function;
    // filterKey: string;
    searchTermLocal?: string;
    setPage?: Function;
}

export const TableContextMenuItemList = (props: TableContextMenuItemListProps) => {
    const { propertyValueHeaders, filters, setFilters, searchTermLocal, setPage } = props;
    return (
        <React.Fragment>
            {propertyValueHeaders.map((propertyValueHeader: QueryPropertyValue, key: number) => {
                const label =
                    propertyValueHeader && propertyValueHeader.label !== null && propertyValueHeader.label !== undefined
                        ? propertyValueHeader.label
                        : '';

                const isChecked = filters.find(
                    (filter: Filters) =>
                        filter.filterField === propertyValueHeader.field &&
                        filter.filterValues.includes(propertyValueHeader.value)
                )
                    ? true
                    : false;

                const handleFilters = () => {
                    const targetFilter = filters.find((f) => f.filterField === propertyValueHeader.field);

                    const initialFilter: Filters = {
                        filterField: propertyValueHeader.field,
                        filterValues: [propertyValueHeader.value],
                        type: propertyValueHeader.type,
                    };
                    // filterField: string;
                    // filterValues: string[] | number[];
                    const _filters = targetFilter
                        ? filters
                              .map((f) => {
                                  if (f.filterField === propertyValueHeader.field) {
                                      const newFilter = { ...f };
                                      const _filterValues = [...f.filterValues];
                                      if (_filterValues.includes(propertyValueHeader.value)) {
                                          const availableValues = propertyValueHeaders.map((p) => {
                                              return p.value;
                                          });
                                          const newFilterValues: (string | number | null | boolean)[] =
                                              _filterValues.filter(
                                                  (fi: string | number | null | boolean) =>
                                                      fi !== propertyValueHeader.value && availableValues.includes(fi)
                                              );

                                          newFilter.filterValues = newFilterValues;
                                          if (newFilterValues.length === 0) {
                                              return null;
                                          }
                                      } else {
                                          _filterValues.push(propertyValueHeader.value);
                                          newFilter.filterValues = _filterValues;
                                      }
                                      return newFilter;
                                  } else {
                                      return f;
                                  }
                              })
                              .filter((r) => r !== null)
                        : [...filters, initialFilter];

                    if (setPage) {
                        setPage(0);
                    }
                    if (setFilters) {
                        setFilters(_filters);
                    }
                };

                if (searchTermLocal && searchTermLocal.length > 0) {
                    if (!label.toLowerCase().includes(searchTermLocal.toLowerCase())) {
                        return null;
                    }
                }

                return (
                    <div
                        style={{ paddingTop: '4px', paddingBottom: '4px' }}
                        key={key}
                        className="checkbox-with-label-wrapper-left on-mouse-down-show"
                        onClick={() => {
                            handleFilters();
                        }}
                    >
                        <div className="checkbox-with-label-wrapper on-mouse-down-show">
                            <input
                                name="isChecked"
                                value={''}
                                checked={isChecked}
                                onChange={() => {}}
                                className="checkbox-primary on-mouse-down-show"
                                type="checkbox"
                            ></input>

                            <label className="on-mouse-down-show">{label}</label>
                        </div>
                    </div>
                );
            })}
        </React.Fragment>
    );
};

export const TableContextMenuSelectList = (props: TableContextMenuItemListProps) => {
    const { propertyValueHeaders, filters, setFilters, /* filterKey */ setPage } = props;
    const [searchTermLocal, setSearchTermLocal] = React.useState('');
    return (
        <div id="no-hover" className="table-context-menu-selector on-mouse-down-show">
            <div className="table-input-container on-mouse-down-show">
                <CustomInput
                    className={'on-mouse-down-show'}
                    onInputChange={(e: any) => {
                        setSearchTermLocal(e.target.value);
                    }}
                    icon={<Search />}
                    placeholder={translate('Search')}
                />
            </div>
            <div className="mt-4p mb-4p mr-4p ml-4p pt-4p  border-bottom on-mouse-down-show">
                <div
                    className="mb-4p on-mouse-down-show"
                    onClick={() => {
                        const filterField =
                            propertyValueHeaders && propertyValueHeaders.length > 0
                                ? propertyValueHeaders[0].field
                                : null;
                        const type =
                            propertyValueHeaders && propertyValueHeaders.length > 0
                                ? propertyValueHeaders[0].type
                                : null;
                        if (filterField) {
                            //   const allfilterValues: (string | number | boolean | null ) []= [];
                            const filteredFilterValues: (string | number | boolean | null)[] = [];
                            for (const propertyValueHeader of propertyValueHeaders) {
                                if (
                                    propertyValueHeader.label !== undefined &&
                                    propertyValueHeader.label !== null &&
                                    propertyValueHeader.label
                                        .toString()
                                        .toLowerCase()
                                        .includes(searchTermLocal.toLowerCase())
                                ) {
                                    filteredFilterValues.push(propertyValueHeader.value);
                                } else if (propertyValueHeader.value === null || propertyValueHeader.value === '') {
                                    if (propertyValueHeader.value === null) {
                                        filteredFilterValues.push(null);
                                    } else {
                                        filteredFilterValues.push('');
                                    }
                                }
                            }

                            const targetFilter = filters.find((f) => f.filterField === filterField);
                            //   const newFilteredArray: (string | number | boolean | null)[] = [];
                            if (targetFilter) {
                                const filterValues =
                                    (targetFilter.filterField === 'statusEnum' &&
                                        checkArrayEquality(targetFilter.filterValues, [0, 1, 2, 4, 5])) ||
                                    (targetFilter.filterField === 'resStatus' &&
                                        checkArrayEquality(targetFilter.filterValues, [2, 4]))
                                        ? filteredFilterValues
                                        : targetFilter.filterValues;
                                const found = filteredFilterValues.every((r) => filterValues.includes(r));
                                const newFilterValues = found
                                    ? filterValues.filter((item) => !filteredFilterValues.includes(item))
                                    : filteredFilterValues;
                                const newFilters = filters
                                    .map((filter) => {
                                        if (filter.filterField !== filterField) {
                                            return filter;
                                        } else {
                                            const newFilter = { ...filter };
                                            newFilter.filterValues = newFilterValues;
                                            if (newFilterValues.length > 0) {
                                                return newFilter;
                                            } else {
                                                return null;
                                            }
                                        }
                                    })
                                    .filter((f) => f !== null);
                                if (setFilters) {
                                    setFilters(newFilters);
                                }
                            } else {
                                const newFilterObject: Filters = {
                                    filterField: filterField,
                                    filterValues: filteredFilterValues,
                                    type: type ? type : '',
                                };

                                const newFilters =
                                    newFilterObject?.filterValues && newFilterObject.filterValues.length > 0
                                        ? [...filters, newFilterObject]
                                        : filters;
                                if (setFilters) {
                                    setFilters(newFilters);
                                }
                            }
                        }
                    }}
                >
                    <span className="border pr-2p pl-2p mr-8p ml-8p">-</span>
                    <span className="on-mouse-down-show">{translate('Select all')}</span>
                </div>
            </div>
            <TableContextMenuItemList
                propertyValueHeaders={propertyValueHeaders}
                filters={filters}
                setFilters={setFilters}
                searchTermLocal={searchTermLocal}
                setPage={setPage}
            />
        </div>
    );
};

export interface GetLabel {
    headerItem: HeaderItem;
    arrayItem: any;
    locale?: string;
    exportData?: any;
    setExportData?: Function;
    companyInfo?: any; //treba napraviti
    history?: any;
    tax?: TaxModel;
    styled?: boolean;
    room?: RoomInfoModel;
    customerName?: string;
    companyName?: string;
    srcProxy?: string;
    userName?: string;
    typeAndClasses?: TypeAndClassModel[];
}

export const getLabel = (props: GetLabel) => {
    const {
        headerItem,
        arrayItem,
        locale,
        companyInfo,
        history,
        tax,
        styled,
        room,
        customerName,
        companyName,
        srcProxy,
        userName,
        typeAndClasses,
    } = props;
    let label: any = '';

    switch (headerItem.type) {
        case 'date':
            if (isNaN(Number(arrayItem[headerItem.label]))) {
                label = arrayItem[headerItem.label]
                    ? moment(arrayItem[headerItem.label])
                          .locale(locale ? locale : 'en')
                          .format('LL')
                    : '-';
            } else {
                label = arrayItem[headerItem.label]
                    ? moment(Number(arrayItem[headerItem.label]))
                          .locale(locale ? locale : 'en')
                          .format('LL')
                    : '-';
            }
            break;
        case 'datetime':
            if (isNaN(Number(arrayItem[headerItem.label]))) {
                label = arrayItem[headerItem.label]
                    ? moment(arrayItem[headerItem.label])
                          .locale(locale ? locale : 'en')
                          .format('LLL')
                    : '-';
            } else {
                label = arrayItem[headerItem.label]
                    ? moment(Number(arrayItem[headerItem.label]))
                          .locale(locale ? locale : 'en')
                          .format('LLL')
                    : '-';
            }
            break;
        case 'timestamp':
            if (isNaN(Number(arrayItem[headerItem.label]))) {
                label = arrayItem[headerItem.label]
                    ? moment(arrayItem[headerItem.label])
                          .locale(locale ? locale : 'en')
                          .format('LLL')
                    : '-';
            } else {
                label = arrayItem[headerItem.label]
                    ? moment(Number(arrayItem[headerItem.label]))
                          .locale(locale ? locale : 'en')
                          .format('LLL')
                    : '-';
            }
            break;
        case 'enum':
            label = translate(headerItem.enumDescription[arrayItem[headerItem.filterKey]]);
            break;
        case 'num':
            if (arrayItem[headerItem.label] === null) {
                label = '';
            } else {
                label = Number(arrayItem[headerItem.label]);
            }
            break;
        case 'boolean':
            if (arrayItem[headerItem.label] === null) {
                label = '';
            } else {
                label = translate('Yes');
            }
            break;

        case 'hotelName':
            label = companyInfo?.hotelName;
            break;

        case 'link':
            let temp = [];
            let j = 0;
            if (headerItem.linkSchema && headerItem.linkSubstitutions) {
                for (let i = 0; i < headerItem.linkSchema.length; i++) {
                    let itm = headerItem.linkSchema[i];
                    if (headerItem.linkSchema[i] === '*') {
                        itm = arrayItem[headerItem.linkSubstitutions[j]]
                            ? arrayItem[headerItem.linkSubstitutions[j]]
                            : 'null';
                        j++;
                    }
                    temp.push(itm);
                }

                if (headerItem.linkSchema[0] === 'reservationDetails') {
                    temp.push('?groupUuid=' + arrayItem[headerItem.linkSubstitutions[1]]);
                } else {
                }
                let linkToVisit = '/' + temp.join('/');
                label = null;
                const disableLinkToResDetails =
                    temp[0] === 'reservationDetails' &&
                    temp[1] &&
                    temp[1] === 'null' &&
                    temp[2] &&
                    temp[2].split('=')[1] === 'null';

                if (headerItem.openInNewTab) {
                    label = disableLinkToResDetails ? null : (
                        <Link to={linkToVisit} className="w-100" target="_blank">
                            <div className="custom-small-button-reports text-dark">{headerItem.linkText}</div>
                        </Link>
                    );
                } else {
                    label = disableLinkToResDetails ? null : (
                        <div
                            onClick={() => {
                                if (history) {
                                    history.push(linkToVisit);
                                }
                            }}
                            className="custom-small-button-reports"
                        >
                            {headerItem.linkText}
                        </div>
                    );
                }
            }

            break;

        case 'price':
            label = numbro(arrayItem[headerItem.label] ? arrayItem[headerItem.label] : 0).format({
                mantissa: 2,
                trimMantissa: false,
                thousandSeparated: true,
            });

            break;

        case 'status':
            label = styled ? (
                <div className={arrayItem[headerItem.label] ? 'color-success uppercase' : 'color-error uppercase'}>
                    {arrayItem[headerItem.label] ? <b>{translate('active')}</b> : translate('inactive')}
                </div>
            ) : arrayItem[headerItem.label] ? (
                translate('active').charAt(0).toUpperCase() + translate('active').slice(1)
            ) : (
                translate('inactive').charAt(0).toUpperCase() + translate('inactive').slice(1)
            );

            break;

        case 'badge':
            let badges = arrayItem[headerItem.label]
                ? typeof arrayItem[headerItem.label] === 'string'
                    ? arrayItem[headerItem.label].split(';')
                    : arrayItem[headerItem.label]
                : [];
            label = (
                <div className="truncated-inline-block text-left">
                    {badges.map((c: string, key: number) => {
                        return (
                            <span key={key} className="badge badge-success mr-1 p-1">
                                {c}
                            </span>
                        );
                    })}
                </div>
            );

            break;
        case 'reservationStatusBadge':
            let reservationStatusBadges = arrayItem[headerItem.label]
                ? typeof arrayItem[headerItem.label] === 'string'
                    ? arrayItem[headerItem.label].split(';')
                    : arrayItem[headerItem.label]
                : [];
            label = (
                <div className="flex-center">
                    {reservationStatusBadges.map((c: string, key: number) => {
                        // const color = RoomReservationStatusColors[c as keyof typeof RoomReservationStatusColors];
                        return (
                            <div
                                style={{
                                    border: '1px solid',
                                    borderRadius: '4px',
                                    borderColor:
                                        RoomReservationStatusColors[c as keyof typeof RoomReservationStatusColors],
                                }}
                                key={key}
                                className="truncated mr-1 p-1"
                            >
                                <span>
                                    {translate(RoomReservationStatusText[c as keyof typeof RoomReservationStatusText])}
                                </span>
                            </div>
                        );
                    })}
                </div>
            );

            break;
        case 'badgePrimary':
            let badgesPrimary = arrayItem[headerItem.label]
                ? typeof arrayItem[headerItem.label] === 'string'
                    ? arrayItem[headerItem.label].split(';')
                    : arrayItem[headerItem.label]
                : [];
            label = (
                <div className="truncated-inline-block text-left">
                    {badgesPrimary.map((c: string, key: number) => {
                        return (
                            <span key={key} className="badge badge-primary mr-1 p-1">
                                {c}
                            </span>
                        );
                    })}
                </div>
            );

            break;

        case 'tax':
            label = !styled ? tax?.name : <div className="truncated-inline-block text-left">{tax?.name}</div>;

            break;
        case 'room':
            label = room?.name;

            break;

        case 'customer':
            label = customerName ? customerName : '';

            break;
        case 'company':
            label = companyName ? companyName : '';

            break;
        case 'srcProxy':
            label = srcProxy ? srcProxy : '';
            break;

        case 'user':
            label = userName ? userName : '';

            break;
        case 'posTerminalName':
            label = arrayItem[headerItem.label] !== null ? arrayItem[headerItem.label] : 'Checkout terminal';
            break;

        case 'country-code':
            const country = countryCodes.find((c) => c['country-code'] === arrayItem[headerItem.label]);
            label = country ? translate(country.name) : translate('Unknown');
            break;

        case 'num_code':
            const nationality = citizenshipCode.find((c) => c['num_code'] === arrayItem[headerItem.label]);
            label = nationality ? translate(nationality.nationality) : translate('Unknown');
            break;

        case 'modal':
            if (headerItem.showRowsWithIds) {
                label = headerItem.showRowsWithIds.includes(arrayItem.id) ? (
                    <div
                        onClick={() => {
                            if (headerItem.action) {
                                headerItem.action(arrayItem);
                            }
                        }}
                        className="custom-small-button-reports"
                    >
                        {headerItem.linkText}
                    </div>
                ) : null;
            }
            break;

        case 'address':
            let labelCommaStringAddress = headerItem.label;
            let labelArrayAddress = labelCommaStringAddress.split(',');
            if (labelArrayAddress.length > 1) {
                labelArrayAddress.forEach((labItm: any, index: number) => {
                    if (arrayItem[labItm]) {
                        label =
                            index < labelArrayAddress.length - 1 && arrayItem[labelArrayAddress[index + 1]]
                                ? label + arrayItem[labItm] + ', '
                                : label + arrayItem[labItm];
                    }
                });
            } else {
                labelArrayAddress.forEach((labItm: any) => {
                    if (arrayItem[labItm]) {
                        label = label + arrayItem[labItm];
                    }
                });
            }

            break;

        case 'docType':
            const typeEnum = Object.keys(StockDocumentTypesEnum)?.find(
                (t: string) =>
                    StockDocumentTypesEnum[t as keyof typeof StockDocumentTypesEnum] === arrayItem[headerItem.label]
            );
            label = typeEnum ? translate(typeEnum) : '';
            break;

        case 'balance':
            label = Number(arrayItem[headerItem.label]) > 0 ? Number(arrayItem[headerItem.label]) : 0;
            break;

        case 'glType':
            const glType = typeAndClasses?.find((t) => t.id === arrayItem[headerItem.label]);
            label = glType ? translate(glType?.name) : '';
            break;

        case 'glClass':
            const glClass = typeAndClasses?.find((t) => t.id === arrayItem[headerItem.label]);
            label = glClass ? translate(glClass?.name) : '';
            break;

        case 'typeAndClass':
            label = translate(TypeAndClassEnumText[arrayItem[headerItem.label]]);
            break;

        case 'aggregationType':
            const glReportType = arrayItem[headerItem.label] ? GLReportDocTypeName[arrayItem[headerItem.label]] : '';
            label = glReportType ? translate(glReportType) : '';
            break;

        default:
            let labelCommaString = headerItem.label;
            let labelArray = labelCommaString.split(',');
            if (labelArray.length > 1) {
                labelArray.forEach((labItm: any) => {
                    if (arrayItem[labItm]) {
                        label = translate(label + arrayItem[labItm]) + ' ';
                    }
                });
            } else {
                labelArray.forEach((labItm: any) => {
                    if (arrayItem[labItm]) {
                        label = translate(label + arrayItem[labItm]);
                    }
                });
            }

            break;
    }

    return label;
};

export interface TableHeaderFilterProps {
    headers: any[];
    setHeadersFilter: Function;
    headersFilter: string[];
    setFilters?: Function;
    filters?: Filters[];
}
export const TableHeaderFilter = (props: TableHeaderFilterProps) => {
    const { headers, setHeadersFilter, headersFilter, setFilters, filters } = props;
    const [searchTermLocal, setSearchTermLocal] = React.useState('');
    const removeAllFilters = () => {
        if (setFilters) {
            setFilters([]);
        }
    };

    let color: string = '';

    if (filters && filters.length > 0) {
        const _filterValues = [];
        for (const filter of filters) {
            const { filterValues } = filter;
            if (filterValues) {
                for (const filterValue of filterValues) {
                    _filterValues.push(filterValue);
                }
            }
        }
        if (_filterValues.length > 0) {
            color = 'fill-red';
        }
    }

    return (
        <div id="no-hover" className="on-mouse-down-show">
            <div
                className="flex-center-left on-mouse-down-show"
                onClick={() => {
                    removeAllFilters();
                }}
            >
                <div className={'button-white-w-icon-only on-mouse-down-show ' + color} onClick={() => {}}>
                    <Remove />
                </div>
                <div className="on-mouse-down-show">Remove filters</div>
            </div>

            <div className="table-input-container on-mouse-down-show">
                <CustomInput
                    className={'on-mouse-down-show'}
                    onInputChange={(e: any) => {
                        setSearchTermLocal(e.target.value);
                    }}
                    icon={<Search />}
                    placeholder={translate('Search')}
                />
            </div>
            <div className="mt-8p mb-4p mr-4p ml-4p border-bottom on-mouse-down-show">
                <div
                    className="on-mouse-down-show"
                    onClick={() => {
                        const filteredFilterValues: string[] = [];
                        headers.forEach((h) => {
                            if (h.headerTitle && h.headerTitle.toLowerCase().includes(searchTermLocal.toLowerCase())) {
                                filteredFilterValues.push(h.filterKey);
                            }
                        });
                        const found = filteredFilterValues.every((r) => headersFilter.includes(r));
                        const newHeadersFilter = found
                            ? headersFilter.filter((item) => !filteredFilterValues.includes(item))
                            : filteredFilterValues;

                        setHeadersFilter(newHeadersFilter);
                    }}
                >
                    <span className="on-mouse-down-show">
                        <ChevronRight />
                    </span>
                    <span className="on-mouse-down-show">{translate('Select all')}</span>
                </div>
            </div>
            <React.Fragment>
                {headers.map((h, key) => {
                    if (h.filterKey === '#') {
                        return null;
                    }
                    const isChecked = headersFilter?.includes(h.filterKey) ? false : true;
                    if (searchTermLocal && searchTermLocal.length > 0) {
                        if (!h.headerTitle.toLowerCase().includes(searchTermLocal.toLowerCase())) {
                            return null;
                        }
                    }
                    return (
                        <div
                            style={{ paddingTop: '4px', paddingBottom: '4px' }}
                            key={key}
                            className="checkbox-with-label-wrapper-left on-mouse-down-show"
                            onClick={() => {
                                const _headersFilter = [...headersFilter];
                                if (_headersFilter.includes(h.filterKey)) {
                                    const newHeadersFilter = _headersFilter.filter((hf) => hf !== h.filterKey);

                                    setHeadersFilter(newHeadersFilter);
                                } else {
                                    _headersFilter.push(h.filterKey);
                                    setHeadersFilter(_headersFilter);
                                }
                            }}
                        >
                            <div className="checkbox-with-label-wrapper on-mouse-down-show">
                                <input
                                    name="filters"
                                    value={''}
                                    checked={isChecked}
                                    onChange={() => {}}
                                    className="checkbox-primary on-mouse-down-show"
                                    type="checkbox"
                                ></input>

                                <label className="on-mouse-down-show">{translate(h.headerTitle)}</label>
                            </div>
                        </div>
                    );
                })}
            </React.Fragment>
        </div>
    );
};

// Potrebno samo za Liste
function isJsonString(str: string) {
    try {
        const res = Array.isArray(JSON.parse(str));
        return res;
    } catch (e) {
        return false;
    }
}

export const filterListItems = (listItems: any[], filters: Filters[]) => {
    //Any zbog toga sto moze biti bilo koji modelDefinition
    const result =
        filters.length > 0
            ? listItems.filter((listItem: any) => {
                  const itemProperties = Object.keys(listItem);

                  const temp: any[] = [];
                  itemProperties.forEach((property) => {
                      const targetFilter = filters.find((f) => f.filterField === property);
                      if (targetFilter) {
                          const filteredValues = targetFilter.filterValues;
                          const value =
                              listItem[property as keyof any] !== null
                                  ? listItem[property as keyof any] === false
                                      ? [false]
                                      : typeof listItem[property as keyof any] === 'string'
                                      ? isJsonString(listItem[property as keyof any])
                                          ? JSON.parse(listItem[property as keyof any])
                                          : listItem[property as keyof any].split(';').map((r: string) => {
                                                return r;
                                            })
                                      : Array.isArray(listItem[property as keyof any])
                                      ? listItem[property as keyof any]
                                      : [listItem[property as keyof any]]
                                  : [''];

                          if (property === 'remoteDeviceId') {
                              const roomStatus = getRoomSRCStatus(listItem.remoteDeviceId);
                              const roomStatus2 = getRoomSRCStatus(listItem.remoteDeviceId2);
                              const roomStatus3 = getRoomSRCStatus(listItem.remoteDeviceId3);

                              if (
                                  (filteredValues.includes(roomStatus) ||
                                      filteredValues.includes(roomStatus2) ||
                                      filteredValues.includes(roomStatus3)) &&
                                  !temp.includes(value)
                              ) {
                                  temp.push(listItem.id);
                              }
                          } else if (targetFilter.type === 'date' && property === targetFilter.filterField) {
                              for (const filteredValue of filteredValues) {
                                  const isSameDay =
                                      typeof filteredValue === 'number' || typeof filteredValue === 'string'
                                          ? moment(listItem[property]).isSame(moment(filteredValue), 'day')
                                          : false;
                                  if (!temp.includes(value) && isSameDay) {
                                      temp.push(listItem.id);
                                  }
                              }
                          } else if (
                              filteredValues.some(
                                  (v) => value.includes(v) || (value.includes('') && filteredValues.includes(null))
                              ) &&
                              !temp.includes(value)
                          ) {
                              temp.push(listItem.id);
                          }
                      }
                  });

                  if (temp.includes(listItem.id)) {
                      return listItem;
                  } else {
                      return null;
                  }
              })
            : listItems;
    return result;
};

export interface Sort {
    [key: string]: string;
}

export interface CustomersOrCompaniesName {
    id: number | null;
    name: string;
}

interface userName {
    uuid: string | null;
    name: string;
}
export interface ListTableHeaderProps {
    children?: any;
    headers: any;
    headersFilter?: string[];
    listArray: any[];
    showContextMenu: string | null;
    setShowContextMenu: Function;
    setHeadersFilter?: Function;
    filters: Filters[];
    setFilters: Function;
    setPage: Function;
    setSort: Function;
    sort?: Sort;
    history: any;
    taxes?: TaxModel[];
    rooms?: RoomInfoModel[];
    locale?: string;
    className?: string;
    customersName?: CustomersOrCompaniesName[];
    companiesName?: CustomersOrCompaniesName[];
    srcProxyNames?: CustomersOrCompaniesName[];
    attributes?: any[];
    isPrimaryKey?: boolean;
    userNames?: userName[];
    typeAndClasses?: TypeAndClassModel[];
}

export const ListTableHeader = (props: ListTableHeaderProps) => {
    const {
        //  sort,
        setSort,
        children,
        headers,
        headersFilter,
        setHeadersFilter,
        listArray,
        showContextMenu,
        setShowContextMenu,
        filters,
        setFilters,
        setPage,
        history,
        taxes,
        rooms,
        className,
        customersName,
        companiesName,
        srcProxyNames,
        attributes,
        isPrimaryKey,
        userNames,
        typeAndClasses,
    } = props;

    const locale: string = useStore.getState().locale;

    const sortByColumn = (column: string, sortType: string) => {
        const _sort: Sort = {};
        _sort[column] = sortType;
        setSort(_sort);
    };

    return (
        <div className={className ? className : 'custom-list-header'}>
            {headers.map((headerItem: any, index: number) => {
                const newHeaderItem = { ...headerItem };
                newHeaderItem.filterKey = headerItem.filterKey?.replace('Closed', '');
                newHeaderItem.filterKey = headerItem.filterKey?.replace('1', '');

                const propertyValueHeaders = getQueryPropertyValue({
                    arrayOfObjects: listArray,
                    headerItem: newHeaderItem,
                    history,
                    taxes,
                    locale,
                    rooms,
                    customersName,
                    companiesName,
                    srcProxyNames,
                    attributes,
                    isPrimaryKey,
                    userNames,
                    typeAndClasses,
                });

                if (headersFilter && headersFilter.includes(headerItem.filterKey) && headerItem.filterKey !== '#') {
                    return null;
                }

                return showContextMenu !== headerItem.filterKey ? (
                    <div
                        className={headerItem.type === 'documentLink' ? 'justify-content-center' : ''}
                        style={{ minWidth: headerItem.cellWidth, maxWidth: headerItem.cellWidth }}
                        onClick={() => {
                            if (
                                headerItem.headerTitle !== translate('Action') &&
                                headerItem.headerTitle !== translate('Actions') &&
                                headerItem.type !== 'documentLink' &&
                                headerItem.headerTitle.length > 0
                            ) {
                                setShowContextMenu(headerItem.filterKey);
                            }
                        }}
                        key={`hindex${index}`}
                    >
                        {headerItem.headerTitle}{' '}
                        {headerItem.headerTitle !== translate('Action') &&
                        headerItem.headerTitle !== translate('Actions') &&
                        headerItem.type !== translate('documentLink') &&
                        headerItem.headerTitle.length > 0 ? (
                            <ChevronDown />
                        ) : (
                            ''
                        )}
                    </div>
                ) : (
                    <div
                        style={{ position: 'relative', minWidth: headerItem.cellWidth, maxWidth: headerItem.cellWidth }}
                        key={`hindex${index}`}
                    >
                        <div
                            onClick={() => {
                                setShowContextMenu(null);
                            }}
                        >
                            {headerItem.headerTitle} <ChevronDown />
                        </div>
                        <TableContextMenu fontSize="12px" setShowContextMenu={setShowContextMenu}>
                            {showContextMenu === '#' && setHeadersFilter && headersFilter ? (
                                <TableHeaderFilter
                                    headers={headers}
                                    setHeadersFilter={setHeadersFilter}
                                    headersFilter={headersFilter}
                                    setFilters={setFilters}
                                    filters={filters}
                                />
                            ) : (
                                <React.Fragment>
                                    <TableSortOptions
                                        sortByColumn={(sort: string) => {
                                            sortByColumn(newHeaderItem.filterKey, sort);
                                        }}
                                        setShowContextMenu={setShowContextMenu}
                                    />
                                    <TableContextMenuSelectList
                                        propertyValueHeaders={propertyValueHeaders}
                                        filters={filters}
                                        setFilters={setFilters}
                                        setPage={setPage}
                                    />
                                </React.Fragment>
                            )}
                        </TableContextMenu>
                    </div>
                );
            })}
            {children}
        </div>
    );
};

interface GetFilteredColumns {
    headersFilter?: string[];
    headers: any[];
    listItem: any;
    history: any;
    tax?: TaxModel;
    defaultCurrency?: string;
    room?: RoomInfoModel;
    customerName?: string;
    companyName?: string;
    srcProxy?: string;
    attributes?: any[];
    userName?: string;
    parentElement?: any;
    typeAndClasses?: TypeAndClassModel[];
}

export const FilteredColumns = (props: GetFilteredColumns) => {
    const {
        headersFilter,
        headers,
        listItem,
        history,
        tax,
        defaultCurrency,
        room,
        customerName,
        attributes,
        companyName,
        srcProxy,
        userName,
        parentElement,
        typeAndClasses,
    } = props;

    const locale: string = useStore.getState().locale;

    const filteredColumns = headers.map((val: any, key: number) => {
        if (headersFilter && headersFilter.includes(val.filterKey) && val.filterKey !== '#') {
            return null;
        }
        if (val.filterKey === '#') {
            return (
                <div
                    className="truncated-inline-block text-left"
                    style={{ minWidth: val.cellWidth, maxWidth: val.cellWidth }}
                    key={key}
                >
                    {listItem[val.label]}
                </div>
            );
        } else if (val.type === 'image' && listItem.id) {
            return (
                <div key={'image' + listItem.id} className="truncated-inline-block text-left">
                    <Image
                        posItemId={listItem.id}
                        imageHash={listItem.imageHash}
                        style={{ height: '30px', minWidth: '30px' }}
                    />
                </div>
            );
        } else if (val.type === 'roomAttributes' && attributes && room) {
            return (
                <div key={'roomAttributes' + listItem.id} className="truncated ">
                    <RoomAttributes room={room} attributes={attributes} />
                </div>
            );
        } else if (val.filterKey === 'description' && room) {
            return (
                <div key={'description' + listItem.id} className="elipsis">
                    {room.availableForRent ? (
                        room.description
                    ) : (
                        <div className="text-warning text-center ml-1">{translate('OUT OF SERVICE')}</div>
                    )}
                </div>
            );
        } else if (val.filterKey === 'description' && listItem?.SRCProxyUUID) {
            return (
                <div key={'description' + listItem.id} className="elipsis">
                    {listItem.offlineId ? (
                        <div className="text-danger text-center mr-8p">{translate('OFFLINE POS')}</div>
                    ) : null}
                    {listItem.description}
                </div>
            );
        } else if (val.filterKey === 'bedCount' && room) {
            return (
                <div key={'bedCount' + listItem.id} className="">
                    {room.bedCount + 'x'} <i className="fa fa-bed mx-1" />{' '}
                    {room.spareBedCount ? room.spareBedCount + 'x' : ''}
                    {room.spareBedCount ? <i className="fa fa-bed mx-1 text-info" /> : ''}
                </div>
            );
        } else if (val.filterKey === 'remoteDeviceId' && room) {
            return (
                <div key={'roomSRCStatusIcon' + listItem.id} className="">
                    {room?.remoteDeviceId ? getRoomSRCStatusIcon(room?.remoteDeviceId) : null}
                    {room?.remoteDeviceId2 ? getRoomSRCStatusIcon(room?.remoteDeviceId2) : null}
                    {room?.remoteDeviceId3 ? getRoomSRCStatusIcon(room?.remoteDeviceId3) : null}
                </div>
            );
        } else if (val.filterKey === 'userAssigned' && parentElement) {
            return parentElement;
        } else if (val.headerTitle === translate('Actions')) {
            return null;
        } else if (val.type === 'documentLink' && listItem.userArguments !== undefined) {
            const documents =
                listItem.userArguments && listItem.userArguments.length > 0
                    ? typeof listItem.userArguments === 'string'
                        ? JSON.parse(listItem.userArguments)
                        : listItem.userArguments
                    : [];
            const imageDocument = documents.length > 0 ? documents[0] : null;
            const documentIcon = imageDocument ? getDocumentIconBasedOnFileType(imageDocument) : null;
            return (
                <div
                    key={'documentLink' + listItem.id}
                    className={'truncated-inline-block color-primary text-center'}
                    onClick={() => {
                        if (imageDocument) {
                            openDocument(imageDocument);
                        }
                    }}
                >
                    {documentIcon ? documentIcon : ''}
                </div>
            );
        }
        return (
            <div
                className="truncated-inline-block"
                style={{ minWidth: val.cellWidth, maxWidth: val.cellWidth }}
                key={key}
            >
                {getLabel({
                    headerItem: val,
                    arrayItem: listItem,
                    history,
                    tax,
                    room,
                    styled: true,
                    locale,
                    customerName,
                    companyName,
                    srcProxy,
                    userName,
                    typeAndClasses,
                })}{' '}
                {defaultCurrency && val.type === 'price'
                    ? defaultCurrency
                    : val.filterKey === 'percentageAmount' &&
                      Number(
                          getLabel({
                              headerItem: val,
                              arrayItem: listItem,
                              history,
                              tax,
                              room,
                              styled: true,
                              locale,
                              customerName,
                              companyName,
                              srcProxy,
                              userName,
                              typeAndClasses,
                          })
                      ) !== 0
                    ? '%'
                    : ''}
            </div>
        );
    });
    return <React.Fragment>{filteredColumns}</React.Fragment>;
};
