export const paymentCategoryEVisitorEnum = {
    'A - Djeca : do 12 godina': 1,
    'J - Djeca : od navršenih 12-18 godina': 2,
    'J - Đaci koji nemaju prebivalište u općini ili gradu u kojem se školuju': 4,
    'N - Osobe do 29 godina : članovi međunarodne omladinske organizacije': 5,
    'L - Osobe koje koriste uslugu noćenja u svom mjestu prebivališta': 6,
    'B - Osobe sa tjelesnim invaliditetom 70% i više': 7,
    'B - Pratitelj osobe sa tjelesnim invaliditetom 70% i više': 8,
    'H - Putnici na putničkom brodu u međunarodnom pomorskom prometu kada se brod nalazi na vezu u luci': 9,
    'G - Sezonski radnici - osobe koje zbog potrebe rada ili obavljanja poslova koriste uslugu smještaja u općini ili gradu u kojem nemaju prebivalište, isključivo za vrijeme obavljanja poslova/rada': 10,
    'N - Studenti koji nemaju prebivalište u općini ili gradu u kojem se školuju': 11,
    'C - Sudionici školskih paket aranžmana odobrenih od strane školske ustanove': 12,
    'N - Turist koji koristi usluge chartera': 13,
    'N - Turist koji boravi u ugostiteljskom objektu': 14,
    'N - Osobe koje uslugu noćenja koriste u okviru ostvarivanja programa socijalne skrbi': 15,
    'N - Prijatelji i ostale osobe vlasnika kuće ili stana za odmor': 16,
    'N - Osobe koje koriste uslugu noćenja u stanu ili kući stanovnika grada ili općine': 17,
    'N - Vlasnici kuće za odmor i članovi njegove obitelji': 18,
    'I - Vlasnik izvorne stare obiteljske kuće': 19,
};

export const genderTypesEVisitor = {
    M: 'muški',
    F: 'ženski',
};

export const genderTypesInputEVisitor = {
    Muški: 'muški',
    Ženski: 'ženski',
};

export const visaTypesEVisitor = {
    Name: 'Putna viza',
    Active: true,
    ID: '498dac1e-ea90-4e57-b47e-76383386b66a',
};

export const arrivalOrganisationEVisitor = [
    {
        Name: 'Osobno',
        Active: true,
        CodeMI: 'I',
        Sequence: 1,
        ID: '855cb6a3-c761-4cb6-b527-564ecb202b18',
    },
    {
        Name: 'Agencijski (grupno)',
        Active: true,
        CodeMI: 'A',
        Sequence: 2,
        ID: '6256039d-7c6d-4971-bed1-d268595de870',
    },
    {
        Name: 'Agencijski - (60 dana odgode)',
        Active: true,
        CodeMI: 'O',
        Sequence: 3,
        ID: '6ccb285d-cd7c-4a24-83ca-fed78730b33b',
    },
];

export const offeredServiceType = [
    {
        Name: 'Noćenje',
        Active: true,
        ID: '05a1e646-eec5-4643-a11c-d0defcff4525',
    },
];

export const documentTypesEVisitor = [
    {
        Code: '029',
        Name: 'Pogranična propusnica (strana)',
        Active: true,
        Sequence: 20,
        CodeMI: '029',
        ID: 'ca5eab1e-6592-472c-a506-047de277b143',
    },
    {
        Code: '030',
        Name: 'Izbjeglički karton - izdan od RH',
        Active: true,
        Sequence: 30,
        CodeMI: '030',
        ID: '2affc383-7f2a-44fe-97f8-0d97bb120322',
    },
    {
        Code: '039',
        Name: 'Ostalo',
        Active: true,
        Sequence: 29,
        CodeMI: '039',
        ID: 'd674229c-5012-44a9-9b66-211c547405e2',
    },
    {
        Code: '038',
        Name: 'Pomorska iskaznica (domaća)',
        Active: true,
        Sequence: 19,
        CodeMI: '038',
        ID: '2c8cde0c-4a1d-40f8-bfdd-28caaf153900',
    },
    {
        Code: '005',
        Name: 'Dječja putovnica (strana)',
        Active: true,
        Sequence: 9,
        CodeMI: '005',
        ID: '2cbcf4ae-e9ef-4a33-8e06-29a951ee47e3',
    },
    {
        Code: '037',
        Name: 'Službena putovnica (domaća)',
        Active: true,
        Sequence: 4,
        CodeMI: '037',
        ID: '83d236b7-7cc1-444c-ac61-2ad668c7ae00',
    },
    {
        Code: '006',
        Name: 'Diplomatska putovnica (strana)',
        Active: true,
        Sequence: 13,
        CodeMI: '006',
        ID: 'c25e11bf-700d-49bb-9dd6-30ec3e55bb85',
    },
    {
        Code: '011',
        Name: 'Putni list za strance',
        Active: true,
        Sequence: 23,
        CodeMI: '011',
        ID: '43caefb0-7cf8-408b-9f11-3ceb5f057c6e',
    },
    {
        Code: '022',
        Name: 'Potvrda o oduzimanju ili zadržavanju putne isprave',
        Active: true,
        Sequence: 27,
        CodeMI: '022',
        ID: 'c97142f9-e4db-4f5f-9031-3d07b2684a74',
    },
    {
        Code: '025',
        Name: 'Posebna osobna iskaznica - diplomatska konzularna službena',
        Active: true,
        Sequence: 28,
        CodeMI: '025',
        ID: '95cde15f-5d64-405b-9413-43a16672b90f',
    },
    {
        Code: '999',
        Name: 'Zdravstvena iskaznica',
        Active: true,
        Sequence: 32,
        CodeMI: '999',
        ID: '4879e094-3d02-45bb-a832-4f9b4e9826a6',
    },
    {
        Code: '013',
        Name: 'Putna isprava za osobe bez državljanstva',
        Active: true,
        Sequence: 24,
        CodeMI: '013',
        ID: 'ab46d338-fc23-4eab-b199-6abfdf6e6b1f',
    },
    {
        Code: '033',
        Name: 'Dječja putovnica (domaća)',
        Active: true,
        Sequence: 10,
        CodeMI: '033',
        ID: '3c432e3a-4d6d-4470-a687-792f460ef609',
    },
    {
        Code: '028',
        Name: 'Osobna putovnica (domaća)',
        Active: true,
        Sequence: 6,
        CodeMI: '028',
        ID: 'f0fd87a5-1b1a-480d-8393-8427a8d42bc1',
    },
    {
        Code: '035',
        Name: 'Zajednička putovnica (domaća)',
        Active: true,
        Sequence: 8,
        CodeMI: '035',
        ID: 'ea7e7752-8ce8-44cb-a6a4-8501516d3bd2',
    },
    {
        Code: '103',
        Name: 'Vozačka dozvola (domaća)',
        Active: true,
        Sequence: 16,
        CodeMI: '103',
        ID: 'fc663a29-905c-4ad2-af8d-8bc6b6c00796',
    },
    {
        Code: '052',
        Name: 'Obiteljska putovnica (domaća)',
        Active: true,
        Sequence: 12,
        CodeMI: '052',
        ID: '7e2a4cef-5f25-446e-9162-8ee32c6e72b7',
    },
    {
        Code: '002',
        Name: 'Osobna putovnica (strana)',
        Active: true,
        Sequence: 5,
        CodeMI: '002',
        ID: '70a188b6-6e4d-4a72-a30a-905f05942f4f',
    },
    {
        Code: '032',
        Name: 'Vozačka dozvola (strana)',
        Active: true,
        Sequence: 15,
        CodeMI: '032',
        ID: '55c3556d-e958-4406-a925-9485415663ed',
    },
    {
        Code: '024',
        Name: 'Osobna iskaznica za stranca - izdana od RH',
        Active: true,
        Sequence: 31,
        CodeMI: '024',
        ID: '5df45264-79c1-4eb9-9a60-97bc70e98ad9',
    },
    {
        Code: '036',
        Name: 'Diplomatska putovnica (domaća)',
        Active: true,
        Sequence: 14,
        CodeMI: '036',
        ID: 'dcdf13ee-899f-4ed4-82ca-985e6d084c8f',
    },
    {
        Code: '023',
        Name: 'Potvrda o prijavi gubitka ili nestanka putne isprave',
        Active: true,
        Sequence: 26,
        CodeMI: '023',
        ID: 'eea5f33e-6b5a-4bb1-96b2-9e6576b67c0c',
    },
    {
        Code: '027',
        Name: 'Osobna iskaznica (strana)',
        Active: true,
        Sequence: 1,
        CodeMI: '027',
        ID: 'b65574b0-c6ec-4a73-8e36-b407bb315591',
    },
    {
        Code: '034',
        Name: 'Putni list za strance (strani)',
        Active: true,
        Sequence: 22,
        CodeMI: '034',
        ID: '06d974a6-4dfa-4d3b-9f1d-bb06922b1a05',
    },
    {
        Code: '051',
        Name: 'Osobna iskaznica (domaća)',
        Active: true,
        Sequence: 2,
        CodeMI: '051',
        ID: '515a1693-d93b-4435-aa27-be15717c3f18',
    },
    {
        Code: '009',
        Name: 'Brodarska knjižica (strana)',
        Active: true,
        Sequence: 18,
        CodeMI: '009',
        ID: 'd8a95a76-3c29-42a9-89cf-d509324b67a9',
    },
    {
        Code: '008',
        Name: 'Pomorska knjižica (strana)',
        Active: true,
        Sequence: 17,
        CodeMI: '008',
        ID: '45791bcf-8edc-46e0-b59c-db9ae80fcf30',
    },
    {
        Code: '026',
        Name: 'Isprave izdane na temelju međunarodnih ugovora',
        Active: true,
        Sequence: 21,
        CodeMI: '026',
        ID: '9f6e707d-0a5d-4cb0-849b-df6f9c7bb643',
    },
    {
        Code: '007',
        Name: 'Službena putovnica (strana)',
        Active: true,
        Sequence: 3,
        CodeMI: '007',
        ID: 'f599fef3-5aee-4b47-b2bd-e166aad89ad0',
    },
    {
        Code: '003',
        Name: 'Obiteljska putovnica (strana)',
        Active: true,
        Sequence: 11,
        CodeMI: '003',
        ID: '6ef7880c-9d0a-4ca5-a04c-f403c6671912',
    },
    {
        Code: '004',
        Name: 'Zajednička putovnica (strana)',
        Active: true,
        Sequence: 7,
        CodeMI: '004',
        ID: '9ff40e7e-fe19-4c1b-afd7-f5fa7709df1a',
    },
    {
        Code: '012',
        Name: 'Putna isprava za izbjeglice',
        Active: true,
        Sequence: 25,
        CodeMI: '012',
        ID: '845f79d0-e752-491b-8a67-f91972115943',
    },
    {
        Code: '05',
        Name: 'Dječja putovnica',
        Active: true,
        Sequence: 33,
        CodeMI: '05',
        ID: '13bc1905-461c-4c49-a7b4-653af1c693f8',
    },
    {
        Code: '06',
        Name: 'Diplomatska putovnica',
        Active: true,
        Sequence: 34,
        CodeMI: '06',
        ID: 'cea2c834-525f-4887-8adb-18940ae88ee2',
    },
    {
        Code: '07',
        Name: 'Službena putovnica',
        Active: true,
        Sequence: 35,
        CodeMI: '07',
        ID: 'b63529d6-5063-4d2a-9e6b-9260f9e85cd7',
    },
    {
        Code: '11',
        Name: 'Pomorska knjižica',
        Active: true,
        Sequence: 36,
        CodeMI: '11',
        ID: '369f44f8-ce85-4459-a631-2e1b82605ef9',
    },
    {
        Code: '12',
        Name: 'Brodarska knjižica',
        Active: true,
        Sequence: 37,
        CodeMI: '12',
        ID: 'f0e63e89-4a90-4aaa-9bd6-0457bdb0135a',
    },
    {
        Code: '25',
        Name: 'Pogranična propusnica',
        Active: true,
        Sequence: 38,
        CodeMI: '25',
        ID: '89550584-1978-4134-aff9-b7070546bd9a',
    },
    {
        Code: '98',
        Name: 'Diplomatska osobna iskaznica',
        Active: true,
        Sequence: 43,
        CodeMI: '98',
        ID: 'f381201a-6841-4247-bd3f-6fc9aad6fbb7',
    },
    {
        Code: '65',
        Name: 'Putovnica za stranca',
        Active: true,
        Sequence: 44,
        CodeMI: '65',
        ID: '208bb646-7ff9-420b-8668-7221b88aa270',
    },
    {
        Code: '80',
        Name: 'Specijalna putovnica',
        Active: true,
        Sequence: 45,
        CodeMI: '80',
        ID: '7e93e68a-2255-4290-b106-aad45a43b877',
    },
    {
        Code: '81',
        Name: 'Strana putna isprava za hitne slučajeve (emergency passport)',
        Active: true,
        Sequence: 45,
        CodeMI: '81',
        ID: 'f269ec7a-1d9a-4d17-ac3b-8076d9605933',
    },
    {
        Code: '83',
        Name: 'UNMIK-ova putovnica',
        Active: true,
        Sequence: 46,
        CodeMI: '83',
        ID: '03b8cbe7-fcea-4955-9fa2-b162d2461d7f',
    },
    {
        Code: '82',
        Name: 'UN putovnica',
        Active: true,
        Sequence: 47,
        CodeMI: '82',
        ID: '5772c1c3-80ee-4de4-9744-2e66408dd3a4',
    },
    {
        Code: '77',
        Name: 'Službena osobna iskaznica',
        Active: true,
        Sequence: 48,
        CodeMI: '77',
        ID: '90cc0d9c-74bb-4a65-9e9d-55657dd81328',
    },
    {
        Code: '78',
        Name: 'UN osobna iskaznica',
        Active: true,
        Sequence: 49,
        CodeMI: '78',
        ID: '1b9fd1f6-08af-468b-aa39-b3987b6b664a',
    },
    {
        Code: '28',
        Name: 'Vozačka dozvola (strana)',
        Active: true,
        Sequence: 39,
        CodeMI: '28',
        ID: 'f67e64e9-f175-45da-aafc-27b783da57e4',
    },
    {
        Code: '29',
        Name: 'Putni list za strance (strani)',
        Active: true,
        Sequence: 40,
        CodeMI: '29',
        ID: 'a3e3d112-53fd-497c-8ab6-9188271e7d8d',
    },
    {
        Code: '63',
        Name: 'Putovnica za azilanta',
        Active: true,
        Sequence: 41,
        CodeMI: '63',
        ID: 'd063ed9c-1a01-437f-821a-b524113dcb55',
    },
    {
        Code: '96',
        Name: 'Osobna iskaznica za stranca',
        Active: true,
        Sequence: 42,
        CodeMI: '96',
        ID: '4dd01c44-fd07-4ba1-afdb-73b35e300ed2',
    },
    {
        Code: '04',
        Name: 'Zajednička putna isprava',
        Active: true,
        Sequence: 50,
        CodeMI: '04',
        ID: 'b6223ede-d774-4015-847f-6d80cf840f80',
    },
    {
        Code: '02',
        Name: 'Osobna putovnica (strana)',
        Active: true,
        Sequence: 52,
        CodeMI: '02',
        ID: '4a4b1ba2-b915-430c-85cb-98156c648d54',
    },
    {
        Code: '03',
        Name: 'Obiteljska putovnica (strana)',
        Active: true,
        Sequence: 53,
        CodeMI: '03',
        ID: '9af20522-9b7b-4596-9d57-9c11cd526a34',
    },
    {
        Code: '22',
        Name: 'Putni list za strance',
        Active: true,
        Sequence: 54,
        CodeMI: '22',
        ID: '6831133d-190c-4df8-a2d9-04cde9bff03d',
    },
    {
        Code: '23',
        Name: 'Putna isprava za izbjeglice',
        Active: true,
        Sequence: 55,
        CodeMI: '23',
        ID: '9d3af127-6b05-45e5-8660-90929016b3f3',
    },
    {
        Code: '97',
        Name: 'Osobna iskaznica (strana)',
        Active: true,
        Sequence: 56,
        CodeMI: '97',
        ID: '8ee0e437-14f9-41bf-af7e-c29859c393fb',
    },
    {
        Code: '64',
        Name: 'Putna isprava za osobe bez državljanstva',
        Active: true,
        Sequence: 57,
        CodeMI: '64',
        ID: '64e30c98-6516-468a-8c5e-bcf8e2a5f204',
    },
];

export const paymentCategoryEVisitor = [
    {
        Code: '4',
        Active: true,
        Name: 'Đaci koji nemaju prebivalište u općini ili gradu u kojem se školuju',
        CodeNames: 'J - Đaci koji nemaju prebivalište u općini ili gradu u kojem se školuju',
        ID: '1f573476-5b9f-4d41-8e43-0c2f9ad6cb08',
    },
    {
        Code: '14',
        Active: true,
        Name: 'Turist koji boravi u ugostiteljskom objektu',
        CodeNames: 'N - Turist koji boravi u ugostiteljskom objektu',
        ID: '81d8fa15-bbc1-4cf2-acaf-1423a71d69f3',
    },
    {
        Code: '5',
        Active: true,
        Name: 'Osobe do 29 godina : članovi međunarodne omladinske organizacije',
        CodeNames: 'N - Osobe do 29 godina : članovi međunarodne omladinske organizacije',
        ID: '0ccf762b-746c-4c1d-8563-1f280c9023c0',
    },
    {
        Code: '2',
        Active: true,
        Name: 'Djeca : od navršenih 12-18 godina',
        CodeNames: 'J - Djeca : od navršenih 12-18 godina',
        ID: 'bb339a94-76a2-4648-8ae7-239250ac7437',
    },
    {
        Code: '13',
        Active: true,
        Name: 'Turist koji koristi usluge chartera',
        CodeNames: 'N - Turist koji koristi usluge chartera',
        ID: '865881b0-456d-46c5-80a6-2574bb360b86',
    },
    {
        Code: '8',
        Active: true,
        Name: 'Pratitelj osobe sa tjelesnim invaliditetom 70% i više',
        CodeNames: 'B - Pratitelj osobe sa tjelesnim invaliditetom 70% i više',
        ID: 'd062ddc1-8f40-4900-b935-5578718b8a21',
    },
    {
        Code: '1',
        Active: true,
        Name: 'Djeca : do 12 godina',
        CodeNames: 'A - Djeca : do 12 godina',
        ID: '7156faa5-7e37-4601-b7bf-6265c6973612',
    },
    {
        Code: '9',
        Active: true,
        Name: 'Putnici na putničkom brodu u međunarodnom pomorskom prometu kada se brod nalazi na vezu u luci',
        CodeNames: 'H - Putnici na putničkom brodu u međunarodnom pomorskom prometu kada se brod nalazi na vezu u luci',
        ID: 'dca60b19-94dd-4e15-9bd4-69b7dfb3f5bb',
    },
    {
        Code: '11',
        Active: true,
        Name: 'Studenti koji nemaju prebivalište u općini ili gradu u kojem se školuju',
        CodeNames: 'N - Studenti koji nemaju prebivalište u općini ili gradu u kojem se školuju',
        ID: '4a341513-ed45-42a5-8d7c-a3cef1f17866',
    },
    {
        Code: '10',
        Active: true,
        Name: 'Sezonski radnici - osobe koje zbog potrebe rada ili obavljanja poslova koriste uslugu smještaja u općini ili gradu u kojem nemaju prebivalište, isključivo za vrijeme obavljanja poslova/rada',
        CodeNames:
            'G - Sezonski radnici - osobe koje zbog potrebe rada ili obavljanja poslova koriste uslugu smještaja u općini ili gradu u kojem nemaju prebivalište, isključivo za vrijeme obavljanja poslova/rada',
        ID: 'c580fbff-05a9-41b5-9686-b510bd7fc76c',
    },
    {
        Code: '6',
        Active: true,
        Name: 'Osobe koje koriste uslugu noćenja u svom mjestu prebivališta',
        CodeNames: 'L - Osobe koje koriste uslugu noćenja u svom mjestu prebivališta',
        ID: '1447926b-6bff-4b53-82b5-bacf68e57d32',
    },
    {
        Code: '7',
        Active: true,
        Name: 'Osobe sa tjelesnim invaliditetom 70% i više',
        CodeNames: 'B - Osobe sa tjelesnim invaliditetom 70% i više',
        ID: 'a6ff14ab-d739-4c6c-8b0e-e9a8fcf2b2e8',
    },
    {
        Code: '12',
        Active: true,
        Name: 'Sudionici školskih paket aranžmana odobrenih od strane školske ustanove',
        CodeNames: 'C - Sudionici školskih paket aranžmana odobrenih od strane školske ustanove',
        ID: 'd301544c-1c02-4387-8596-ea9bf12b40ac',
    },
    {
        Code: 'Nepoznato',
        Active: true,
        Name: 'Vlasnici kuće za odmor i članovi njegove obitelji',
        CodeNames: 'N - Vlasnici kuće za odmor i članovi njegove obitelji',
        ID: '8c6661e3-47da-4dc0-bd86-eb1f95d57e17',
    },
    {
        Code: 'Nautika5',
        Active: true,
        Name: 'Djeca od navršenih 12-18 godina : charter',
        CodeNames: 'J - Djeca od navršenih 12-18 godina : charter',
        ID: 'a0d0f344-9a3c-4bb2-8ecc-ee002d3e1450',
    },
    {
        Code: '15',
        Active: true,
        Name: 'Osobe koje uslugu noćenja koriste u okviru ostvarivanja programa socijalne skrbi',
        CodeNames: 'N - Osobe koje uslugu noćenja koriste u okviru ostvarivanja programa socijalne skrbi',
        ID: '6d6a866c-1885-4035-8cfb-48a1a0a35abd',
    },
    {
        Code: 'Nepoznato2',
        Active: true,
        Name: 'Turist koji boravi u stanu ili kući stanovnika općine ili grada',
        CodeNames: 'N - Turist koji boravi u stanu ili kući stanovnika općine ili grada',
        ID: '379f225a-79d2-400e-86ae-373005322f17',
    },
    {
        Code: '16',
        Active: true,
        Name: 'Prijatelji i ostale osobe vlasnika kuće ili stana za odmor',
        CodeNames: 'N - Prijatelji i ostale osobe vlasnika kuće ili stana za odmor',
        ID: 'a373350d-ffaa-460b-bde8-2b0dc7948b14',
    },
    {
        Code: '71',
        Active: false,
        Name: 'Djeca : od navršenih 12-18 godina - prijatelji vlasnika (interna kategorija)',
        CodeNames: 'J - Djeca : od navršenih 12-18 godina - prijatelji vlasnika (interna kategorija)',
        ID: '6584182f-1a5d-4d6e-a279-bb845624c827',
    },
    {
        Code: 'Nautika2',
        Active: true,
        Name: 'Putnik na brodu',
        CodeNames: 'N - Putnik na brodu',
        ID: '10f12275-467a-46cc-97fe-2fcb1ac4fa73',
    },
    {
        Code: 'Nautika1',
        Active: true,
        Name: 'Član posade - profesionalac',
        CodeNames: 'N - Član posade - profesionalac',
        ID: '5a86da59-a672-4611-b97f-f3cf3b500d40',
    },
    {
        Code: 'Nautika4',
        Active: true,
        Name: 'Djeca do 12 godina : charter',
        CodeNames: 'A - Djeca do 12 godina : charter',
        ID: '6eec544d-fc52-4106-a9e7-392c29ed4426',
    },
    {
        Code: 'Nautika3',
        Active: true,
        Name: 'Zapovjednik - profesionalac',
        CodeNames: 'N - Zapovjednik - profesionalac',
        ID: 'a86f9980-a177-4cb0-aafc-b5eda4d447dc',
    },
    {
        Code: '72',
        Active: true,
        Name: 'Nova kategorija BP',
        CodeNames: '66 - Nova kategorija BP',
        ID: 'f92f2d22-4819-4661-8a42-cfc1cabd5877',
    },
    {
        Code: 'Nautika6',
        Active: true,
        Name: 'Zapovjednik - amater',
        CodeNames: 'N - Zapovjednik - amater',
        ID: '7e815265-37aa-402b-bab3-7d8e37f8c80c',
    },
    {
        Code: 'Nautika7',
        Active: true,
        Name: 'Član posade - amater',
        CodeNames: 'N - Član posade - amater',
        ID: '8e0d3a7a-3997-428a-8c42-fd36a95080f6',
    },
    {
        Code: '70',
        Active: false,
        Name: 'Djeca : do 12 godina - prijatelji vlasnika (interna kategorija)',
        CodeNames: 'A - Djeca : do 12 godina - prijatelji vlasnika (interna kategorija)',
        ID: '3f6acd7f-39ae-4f5a-94ea-f91cfa7800d2',
    },
];
