import React from 'react';

function MiniLoader(props) {
	const { display = true } = props;
	return (
		display && (
			<div className="p-1"><i className="fa fa-spinner fa-spin"/></div>
		)
	);
}

export default MiniLoader;