import React, { useState, useEffect } from 'react';
import {
    reservationStatusColorsHEX,
    reservationStatusEnumsDesc,
    reservationStatusColorsHEXCondo,
} from '../../data/reservationStatusEnums';
import Timeline from '../../components/timeline/src';
//@ts-ignore
import moment from 'moment/min/moment-with-locales';
import './legend-checkbox-styling.css';
import { translate } from '../../data/translations';
import { buildMonthsTimebar, buildDaysTimebar } from '../../components/timeline/pimaticoHotelUtils';
import ReservationStatusLegend from './reservationStatusLegend';
import { Reservation, ReservationController } from '@pages/newReservations/resController';
import { ReservationModel, RoomInfoEditModel } from '@common/modelDefinition';
import useStore from '@data/state/zustand';
import { TaskBackgroundColorEnum, TaskStatusEnumText } from '@pages/householdNew/interfaces';
import { saveOrUpdateReservation } from '@pages/newReservations/api';
import InlineModal from '@components/modalInline';
import ConfirmButtons from '@components/buttons/confirmButtons';
const MIN_ZOOM = 2;
const MAX_ZOOM = 100;

const startFrom = new Date().getFullYear() - 1;

let monthsTimebar = buildMonthsTimebar(startFrom, 3, translate('ROOM'));
let daysTimebar = buildDaysTimebar(startFrom, 3, translate('DAY'));
const timebar = [daysTimebar, monthsTimebar];

function getPos(el: any) {
    // yay readability
    for (var lx = 0, ly = 0; el != null; lx += el.offsetLeft, ly += el.offsetTop, el = el.offsetParent);
    return { x: lx, y: ly };
}

interface ReservationsTimelineProps {
    reservationRoute?: string;
    zoom?: number;
    availableFromTimestamp: number;
    availableToTimestamp: number | null;
    selectedRoomsForReservations: number[];
    toggleSelectRoomForReservation: Function;
    setReservationId: Function;
    setReservationUuid: Function;
    toggleGroupReservations: Function;
    groupReservations: boolean;
    showComponents: any;
    ghostTrack?: any;
    basic?: boolean;
    showLegend?: boolean;
    showMarker?: boolean;
    reservations: Reservation[];
    nowMarker?: any;
    activeReservations: Reservation[];
    rooms: RoomInfoEditModel[];
    style?: any;
    setMousePosition: Function;
    groupedCleaningTasksByRoom?: any;
}
let dragOffsetStart = 0;
let dragOffset = 0;
function ReservationsTimeline(props: ReservationsTimelineProps) {
    let reservationRoute = props.reservationRoute ? props.reservationRoute : 'reservations';
    const open: boolean = true;
    const zoom: number = props.zoom ? props.zoom : 80;
    const [hideReservationStatusEnums, setHideReservationStatusEnums] = useState([3] as number[]);
    const [hideCondoReservations, setHideCondoReservations] = useState(false as boolean);
    const [tableContentHeight, setTableContentHeight] = useState(400 as number);
    const [dragAndDropReservation, setDragAndDropReservation] = React.useState(null as Reservation | null);
    const [dropConfirm, setDropConfirm] = React.useState(null as ReservationModel | null);
    const [ghostElementOnDrop, setGhostElementOnDrop] = React.useState(null as any | null);

    const condoOwnerMap = useStore((appState: any) => appState.monkeys.condoOwnerMap());
    const timelineYearsCount: number = useStore(
        (appState: any) => appState.uiStates.accommodationPriceListStack.timelineYearsCount
    );

    const getWidth = () => {
        return Math.max(
            document.body.scrollWidth,
            document.documentElement.scrollWidth,
            document.body.offsetWidth,
            document.documentElement.offsetWidth,
            document.documentElement.clientWidth
        );
    };

    useEffect(() => {
        const loadData = async () => {
            let el = document.getElementById('timeline');
            // let footer = document.getElementById('main-footer');
            let mainAppDiv = document.getElementById('main-app-div');

            let positionOfTableHeaderElement = getPos(el);
            // let positionOfDocumentFooterElement = getPos(footer);
            // let heightOfTableHeaderElement = el.offsetHeight;

            const heightOfTheSpaceFromTableHeaderToDocumentFooter =
                (mainAppDiv?.scrollHeight ? mainAppDiv?.scrollHeight : 0) - positionOfTableHeaderElement.y;
            const _tableContentHeight =
                heightOfTheSpaceFromTableHeaderToDocumentFooter - 50 - (getWidth() <= 600 ? 64 : 23);

            setTableContentHeight(_tableContentHeight);
        };

        loadData().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const loadData = async () => {
            window.dispatchEvent(new Event('timelineScrollToNow'));
        };

        loadData().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, [props.availableFromTimestamp]);

    const toggleHideReservationStatusEnums = (statusEnum: number) => {
        let _hideReservationStatusEnums = [...hideReservationStatusEnums];
        if (hideReservationStatusEnums.includes(statusEnum)) {
            _hideReservationStatusEnums = hideReservationStatusEnums.filter((r) => r !== statusEnum);
        } else {
            _hideReservationStatusEnums.push(statusEnum);
        }
        setHideReservationStatusEnums([..._hideReservationStatusEnums]);
    };

    const toggleHideCondoReservations = (hideRes: boolean) => {
        setHideCondoReservations(hideRes);
    };

    //Prikazi rezervacije po prioritetu
    const statusPriority: any = { 2: 0, 1: 1, 5: 2, 0: 3, 4: 4, 3: 5 };

    function customSort(a: any, b: any) {
        const statusComparison = statusPriority[b.statusEnum] - statusPriority[a.statusEnum];
        if (statusComparison !== 0) {
            return statusComparison;
        }
        return a.id - b.id;
    }

    const getTracksFromRoomsAndReservations = (
        rooms: RoomInfoEditModel[],
        _reservations: Reservation[],
        hideReservationStatusEnums: number[]
    ) => {
        let trackButtonMode = getTrackButtonMode();

        return rooms?.map((room) => {
            if (room) {
                const statusObj =
                    props.groupedCleaningTasksByRoom?.[room?.id] &&
                    props.groupedCleaningTasksByRoom?.[room?.id].length > 0
                        ? props.groupedCleaningTasksByRoom?.[room?.id][0]
                        : null;

                const cleaningStatus = statusObj?.taskStatus ? statusObj.taskStatus : 0;
                const cleaningStatusName = TaskStatusEnumText[cleaningStatus];
                // const cleaningStatusColor = CleaningStatusColors[cleaningStatusName];

                let cleaningStatusColor = cleaningStatus
                    ? TaskBackgroundColorEnum[cleaningStatus]
                    : TaskBackgroundColorEnum[0];

                let hasButton = true;
                let trackButtonIconOverride = null;
                if (trackButtonMode === 'groupReservationsMode') {
                    if (props.selectedRoomsForReservations.includes(room.id)) {
                        trackButtonIconOverride = <i className="fa fa-check-square-o text-dark" />;
                    }
                    hasButton = props.availableFromTimestamp ? true : false;
                }

                let elements = [];
                if (room.id === dropConfirm?.roomInfoId) {
                    elements.push(ghostElementOnDrop);
                }

                const reservations = _reservations.sort(customSort);
                //Prikazi rezervacije po prioritetu

                for (let ind = 0; ind < reservations.length; ind++) {
                    const isCondoOwner =
                        reservations[ind].condoUserUuid !== null && reservations[ind].condoUserUuid !== undefined;

                    const filterCondoRes =
                        hideCondoReservations && reservations[ind].roomInfoId === room.id && isCondoOwner;

                    if (
                        !hideReservationStatusEnums.includes(reservations[ind].statusEnum) &&
                        !filterCondoRes &&
                        reservations[ind].roomInfoId === room.id
                    ) {
                        const r = { ...reservations[ind] };
                        let uninvoiced = null;
                        if (r && r.statusEnum === 4) {
                            if (r.GroupInfo && r.GroupInfo.invoicedStatus !== 1) {
                                uninvoiced = translate('UNINVOICED');
                            } else if (!r.GroupInfo && r.invoicedStatus !== 1) {
                                uninvoiced = translate('UNINVOICED');
                            }
                        }
                        const condoOwner = r.customerId ? condoOwnerMap?.[r.customerId] : null;
                        let customerName = condoOwner
                            ? `${condoOwner.firstName} ${condoOwner.lastName}`
                            : translate('GUEST');

                        if (r.Customer) {
                            customerName = `${r.Customer.firstName} ${r.Customer.lastName}`;
                        } else if (r.Company) {
                            customerName = r.Company.name;
                        }
                        const ctrl = new ReservationController();
                        const resReport = r && r.providerReservation ? ctrl.getReservationValidationReport(r) : null;
                        const overlaps = resReport?.overlaps ? resReport.overlaps : null;

                        let tooltip = (
                            <div>
                                <div className="mb-1">
                                    #{r?.id ? r.id : 'new'} -{' '}
                                    <b>
                                        {customerName}{' '}
                                        {r.notes ? <i className="fa fa-commenting" aria-hidden="true"></i> : ''}
                                    </b>
                                </div>
                                <i className="fa fa-calendar-check-o" /> {moment(r.checkInTimestamp).format('lll')}{' '}
                                <br />
                                <i className="fa fa-sign-out" /> {moment(r.checkOutTimestamp).format('lll')} <br />
                                <div className="mt-3">{reservationStatusEnumsDesc[r.statusEnum]}</div>
                                {/* {uninvoiced ? <div className="mt-3 text-warning">{uninvoiced}</div>:null} */}
                            </div>
                        );

                        elements.push({
                            id: 'reservation-' + r.id ? r.id : 'new',
                            reservation: r,
                            title: `${r.id?.toString() ? r.id.toString() : 'new'} - ${
                                customerName ? customerName : ''
                            } ${''} `, //"REZERVACIJA",
                            unInvoicedTitle: uninvoiced,
                            start: new Date(r.checkInTimestamp),
                            end: new Date(r.checkOutTimestamp),
                            tooltip: dragAndDropReservation ? null : tooltip,
                            style: {
                                backgroundColor:
                                    overlaps && overlaps[0]
                                        ? '#fc8518'
                                        : isCondoOwner
                                        ? r.id
                                            ? reservationStatusColorsHEXCondo[r.statusEnum]
                                            : '#83d9e6'
                                        : r.id
                                        ? reservationStatusColorsHEX[r.statusEnum]
                                        : '#83d9e6',
                                boxShadow: '1px 1px 0px rgba(0, 0, 0, 0.25)',
                                borderBottom: r.id && r.providerReservation ? '2px solid #000000' : '',
                            },
                        });
                    }
                }

                return {
                    id: 'room-' + room.id,
                    title: (
                        <div className="d-flex justify-content-center align-items-center position-relative">
                            <span
                                title={translate(cleaningStatusName)}
                                style={{
                                    backgroundColor: cleaningStatusColor,
                                    display: 'inline-block',
                                    width: '5px',
                                    position: 'absolute',
                                    left: 0,
                                    height: '100%',
                                    color: cleaningStatus ? 'white' : 'black',
                                }}
                            ></span>{' '}
                            <span className="pl-1 text-bold" style={{ fontSize: '14px' }}>
                                {room.name}
                            </span>{' '}
                        </div>
                    ),
                    hasButton,
                    room: room,
                    trackButtonIconOverride,
                    elements: elements,
                };
            } else {
                return null;
            }
        });
    };

    const reservationClickHandler = (element: any) => {
        let reservation = element.reservation;

        if (reservationRoute === 'reservations') {
            props.toggleSelectRoomForReservation(reservation.roomInfoId);
            props.setReservationId(reservation.id);
            props.setReservationUuid(reservation.groupUuid);
        } else {
            props.toggleSelectRoomForReservation(reservation.roomInfoId);
            props.setReservationId(reservation.id);
        }
    };

    const clickTrackButton = (element: any) => {
        switch (getTrackButtonMode()) {
            default:
            case 'roomMode':
                props.toggleSelectRoomForReservation(null);
                if (reservationRoute !== 'condoReservation') {
                    props.toggleGroupReservations(true);
                }
                props.toggleSelectRoomForReservation(element.room.id);
                break;
            case 'reservationMode':
                props.toggleSelectRoomForReservation(null);
                props.toggleSelectRoomForReservation(element.room.id);
                props.toggleGroupReservations(true);
                break;
            case 'groupReservationsMode':
                props.setReservationId(null);
                props.setReservationUuid(null);
                props.toggleSelectRoomForReservation(element.room.id);
                break;
        }
    };

    const getTrackButtonMode = () => {
        let mode = 'roomMode';
        if (props.groupReservations) {
            mode = 'groupReservationsMode';
        } else if (props.showComponents.includes('timeline')) {
            if (props.availableFromTimestamp && props.availableToTimestamp) {
                mode = 'reservationMode';
            } else {
                mode = 'roomMode';
            }
        }
        return mode;
    };

    const onMouseDrag = (e: any) => {
        if (e.buttons === 1) {
            let ev: any = new Event('mouseDragScroll');
            ev.clientX = e.clientX;
            ev.dragOffset = dragOffsetStart - e.clientX;
            if (dragOffset !== ev.dragOffset) {
                dragOffset = ev.dragOffset;
                dragOffsetStart = e.clientX;
                window.dispatchEvent(ev);
            }
        } else {
            dragOffsetStart = e.clientX;
        }
    };

    const { ghostTrack = null, basic = false, showLegend = true, showMarker = true, reservations } = props;

    const start = new Date(`${startFrom}`);
    const end = new Date(`${new Date().getFullYear() + (timelineYearsCount ? timelineYearsCount : 2)}`);

    let nowMarker = props.availableFromTimestamp ? new Date(props.availableFromTimestamp) : new Date();
    if (props.nowMarker) {
        nowMarker = props.nowMarker;
    }

    let scale = {
        start,
        end,
        zoom,
        zoomMin: MIN_ZOOM,
        zoomMax: MAX_ZOOM,
    };

    const newReservations: Reservation[] = props.activeReservations?.filter((r) => !r.id);
    const allReservations: Reservation[] = reservations
        ? reservations.concat(newReservations ? newReservations : [])
        : newReservations
        ? newReservations
        : [];

    let tracks2: any = getTracksFromRoomsAndReservations(props.rooms, allReservations, hideReservationStatusEnums);

    if (ghostTrack) {
        tracks2?.[0]?.elements?.push({
            ...ghostTrack,
            id: 'ghost',
            style: {
                backgroundColor: 'rgba(4, 190, 254, 0.1)',
                borderRadius: '0px',
                top: '-6px',
                height: '40px',
            },
        });
    }

    let trackButtonIcon = null;
    switch (getTrackButtonMode()) {
        default:
        case 'roomMode':
            trackButtonIcon = <i className="fa fa-plus color-primary" style={{ fontWeight: 100 }} />;
            break;
        case 'reservationMode':
            trackButtonIcon = <i className="fa fa-plus color-primary" style={{ fontWeight: 100 }} />;
            break;
        case 'groupReservationsMode':
            trackButtonIcon = <i className="fa fa-square-o text-dark" />;
            break;
    }

    const { style = { height: `${tableContentHeight}px`, overflowY: 'auto' } } = props;

    let Legend = showLegend ? (
        <ReservationStatusLegend
            toggleHideReservationStatusEnums={toggleHideReservationStatusEnums}
            hideReservationStatusEnums={hideReservationStatusEnums}
            toggleHideCondoReservations={toggleHideCondoReservations}
            hideCondoReservations={hideCondoReservations}
        />
    ) : null;

    const onDragStart = (reservation: Reservation) => {
        setDragAndDropReservation(reservation);
    };

    const onDrop = async (roomInfoId: number) => {
        if (dragAndDropReservation) {
            const newReservation: any = { ...dragAndDropReservation, roomInfoId: roomInfoId };
            delete newReservation.Company;
            delete newReservation.Customer;
            delete newReservation.RoomInfo;
            delete newReservation.GroupInfo;
            const reservationForUpdate: ReservationModel = { ...newReservation };
            let customerName = translate('GUEST');
            if (dragAndDropReservation.Customer) {
                customerName = `${dragAndDropReservation.Customer.firstName} ${dragAndDropReservation.Customer.lastName}`;
            } else if (dragAndDropReservation.Company) {
                customerName = dragAndDropReservation.Company.name;
            }

            const ctrl = new ReservationController();
            const resReport =
                reservationForUpdate && reservationForUpdate.providerReservation
                    ? ctrl.getReservationValidationReport(newReservation)
                    : null;
            const overlaps = resReport?.overlaps ? resReport.overlaps : null;
            const ghostElement = {
                id: 'reservation-' + dragAndDropReservation.id ? dragAndDropReservation.id : '',
                reservation: dragAndDropReservation,
                title: `${dragAndDropReservation.id?.toString() ? dragAndDropReservation.id.toString() : ''} - ${
                    customerName ? customerName : ''
                } ${''} `, //"REZERVACIJA",
                unInvoicedTitle: '',
                start: new Date(dragAndDropReservation.checkInTimestamp),
                end: new Date(dragAndDropReservation.checkOutTimestamp),
                tooltip: null,
                style: {
                    backgroundColor: overlaps && overlaps[0] ? '#fc8518' : '#83d9e6',
                    boxShadow: '1px 1px 0px rgba(0, 0, 0, 0.25)',
                    borderBottom:
                        dragAndDropReservation.id && dragAndDropReservation.providerReservation
                            ? '2px solid #000000'
                            : '',
                },
            };

            setGhostElementOnDrop(ghostElement);
            setDropConfirm(reservationForUpdate);
        }
    };
    const dropToRoom = props?.rooms?.find((room) => room.id === dropConfirm?.roomInfoId);
    const dragedFromRoom = props?.rooms?.find((room) => room.id === dragAndDropReservation?.roomInfoId);

    return (
        <div className="clear">
            <InlineModal
                show={dropConfirm} //azurira cijene na pojedinacnom syncu unutar sobe
                width="md"
                header={`${translate('Change room')}`}
                setShow={setDropConfirm}
            >
                {dropConfirm ? (
                    <div className="text-center my-3">
                        <div>{`${translate('Move reservation from room')} ${dragedFromRoom?.name} ${translate(
                            'to room'
                        )} ${dropToRoom?.name}`}</div>
                        <br />
                        <ConfirmButtons
                            onConfirm={async () => {
                                try {
                                    await saveOrUpdateReservation(dropConfirm);
                                } catch (error) {
                                    console.log('ERRROOOORRRR', error);
                                }
                                setDropConfirm(null);
                                setGhostElementOnDrop(null);
                            }}
                            onCancel={async () => {
                                setDropConfirm(null);
                                setGhostElementOnDrop(null);
                            }}
                        />
                    </div>
                ) : null}
            </InlineModal>
            <div
                className="clear"
                id="timeline"
                onClick={(e) => {
                    const _mousePosition = {
                        x: e.clientX,
                        y: e.clientY,
                    };
                    props.setMousePosition({ ..._mousePosition });
                }}
                onMouseMove={(e: any) => onMouseDrag(e)}
            >
                <Timeline
                    style={style}
                    scale={scale}
                    enableSticky
                    clickElement={basic ? null : reservationClickHandler}
                    trackButtonIcon={basic ? null : trackButtonIcon}
                    clickTrackButton={basic ? null : clickTrackButton}
                    scrollToNow
                    isOpen={basic ? false : open}
                    timebar={timebar}
                    tracks={tracks2}
                    now={showMarker ? nowMarker : null}
                    onDragStart={onDragStart}
                    onDrop={onDrop}
                />
            </div>

            {Legend ? <div className="reservation-status-checkboxes-wrapper">{Legend}</div> : null}
        </div>
    );
}

export default ReservationsTimeline;
