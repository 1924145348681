import React, { Component } from 'react';
import './page-loader.css';

class PageLoader extends Component {
    render() {
        let header = this.props.header ? <h4>{this.props.header}</h4> : null;
        let heading = this.props.heading ? <h5>{this.props.heading}</h5> : null;
        let style = this.props.style ? this.props.style : { paddingTop: '10rem' };
        return (
            <div style={style} className={this.props.className ? this.props.className : 'text-center animated fadeIn'}>
                <div className="page-loader-card rounded-lg">
                    {header}
                    <div className="spinner-grow text-primary" role="status">
                        <span className="sr-only">{window.translate("Loading ...")}</span>
                    </div>
                    <div className="spinner-grow text-info" role="status">
                        <span className="sr-only">{window.translate("Loading ...")}</span>
                    </div>
                    <div className="spinner-grow text-primary " role="status">
                        <span className="sr-only">{window.translate("Loading ...")}</span>
                    </div>
                    <div className="spinner-grow text-info" role="status">
                        <span className="sr-only">{window.translate("Loading ...")}</span>
                    </div>
                    <div className="spinner-grow text-primary" role="status">
                        <span className="sr-only">{window.translate("Loading ...")}</span>
                    </div>
                    {heading}
                </div>
            </div>
        );
    }
}

export default PageLoader;
