import _ from 'lodash';
import moment from 'moment/min/moment-with-locales';

var getDaysArray = function (start, end) {
    for (var arr = [], dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
        arr.push(new Date(dt));
    }
    return arr;
};

function buildDayCells(startYear, numberOfYears) {
    let start = new Date(`${startYear}`);
    let end = new Date(`${startYear + numberOfYears}`);
    return _.map(getDaysArray(start, end), (day) => {
        let temp = moment(day).startOf('day');
        return {
            id: `d${temp.valueOf()}`,
            title: temp.format('D'),
            start: new Date(temp.valueOf()),
            end: new Date(temp.add(1, 'd').valueOf()),
        };
    });
}

function buildMonthCells(startYear, numberOfYears) {
    let yearMonthsArray = [];
    for (let j = 0; j < numberOfYears; j++) {
        for (let month = 0; month <= 11; month++) {
            yearMonthsArray.push({
                year: startYear + j,
                month,
            });
        }
    }

    return _.map(yearMonthsArray, (ym) => {
        return {
            id: `y${ym.year}m${ym.month}`,
            title: moment(new Date(ym.year, ym.month)).format('MMM YYYY'),
            start: new Date(ym.year, ym.month),
            end: new Date(ym.year, ym.month + 1),
        };
    });
}

function buildDaysTimebar(startYear, numberOfYears, rowTitle = 'Day') {
    return {
        id: 'days',
        title: rowTitle,
        cells: buildDayCells(startYear, numberOfYears),
        useAsGrid: true,
        style: {},
    };
}

function buildMonthsTimebar(startYear, numberOfYears, rowTitle = 'Room Name') {
    return {
        id: 'months',
        title: rowTitle,
        cells: buildMonthCells(startYear, numberOfYears),
        useAsGrid: true,
        hasButton: true,
        style: {},
    };
}

export { buildMonthsTimebar, buildDaysTimebar };
