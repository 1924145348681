import React, { useRef, useState } from 'react';
//@ts-ignore
import moment from 'moment/min/moment-with-locales';
//@ts-ignore
import Helmet from 'react-helmet';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
//@ts-ignore
import { formatDate, parseDate } from 'react-day-picker/moment';
import useStore from '@data/state/zustand';
import translate from '@data/translations';
import YearMonthForm from '@components/yearDayCalendarSelect';

interface ExampleProps {
    from?: Date;
    to?: Date;
    onChange: Function;
    updateAvail?: boolean;
}

function Example(props: ExampleProps) {
    const [from, setFrom] = useState(
        props.from
            ? props.from
            : (new Date(new Date().getFullYear(), new Date().getMonth() - 1, new Date().getDate()) as Date)
    );
    const [to, setTo] = useState(props.to ? props.to : (new Date() as Date));
    let toRef = useRef(null);

    const otherSettings = useStore((appState: any) => appState.otherSettings);

    const showFromMonth = () => {
        if (!from) {
            return;
        }
        if (moment(to).diff(moment(from), 'months') < 2) {
            //@ts-ignore
            toRef.getDayPicker().showMonth(from);
        }
    };

    const handleFromChange = (from_: Date) => {
        // Change the from date and focus the "to" input field
        const _from = new Date(from_.getFullYear(), from_.getMonth(), from_.getDate(), 0, 0, 0);
        setFrom(_from);
        if (props.onChange) {
            props.onChange('from', _from);
        }
    };

    const handleToChange = (to_: Date) => {
        const _to = new Date(to_.getFullYear(), to_.getMonth(), to_.getDate(), 23, 59, 59);
        setTo(_to);
        showFromMonth();
        if (props.onChange) {
            props.onChange('to', _to);
        }
    };

    const modifiers = { start: from, end: to };

    return (
        <div className="flex-center">
            <div className="InputFromTo">
                <DayPickerInput
                    value={from}
                    placeholder="From"
                    format={otherSettings?.dateFormat ? otherSettings.dateFormat : 'DD/MM/YYYY'}
                    inputProps={{ className: 'date-picker-input-custom' }}
                    formatDate={formatDate}
                    parseDate={parseDate}
                    dayPickerProps={{
                        selectedDays: [from, { from, to }],
                        disabledDays: { after: to, before: props.updateAvail ? new Date() : undefined },
                        toMonth: to,
                        modifiers,
                        numberOfMonths: 1,
                        todayButton: translate('Today'),
                        className: 'text-center',
                        month: from,
                        firstDayOfWeek: 1,
                        //@ts-ignore
                        captionElement: ({ date }) => (
                            <YearMonthForm
                                date={date}
                                onChange={(e: any) => setFrom(e)}
                                minYear={1900}
                                // maxYear={1900}
                            />
                        ),
                    }}
                    onDayChange={handleFromChange}
                />{' '}
            </div>
            —{' '}
            <div className="InputFromTo-to">
                <DayPickerInput
                    //@ts-ignore
                    ref={(el) => (toRef = el)}
                    value={to}
                    placeholder="To"
                    format={otherSettings?.dateFormat ? otherSettings.dateFormat : 'DD/MM/YYYY'}
                    inputProps={{ className: 'date-picker-input-custom' }}
                    formatDate={formatDate}
                    parseDate={parseDate}
                    dayPickerProps={{
                        selectedDays: [from, { from, to }],
                        disabledDays: { before: from },
                        modifiers,
                        fromMonth: from,
                        numberOfMonths: 1,
                        todayButton: translate('Today'),
                        className: 'text-center',
                        month: to,
                        firstDayOfWeek: 1,
                        //@ts-ignore
                        captionElement: ({ date }) => (
                            <YearMonthForm
                                date={date}
                                onChange={(e: any) => setTo(e)}
                                minYear={1900}
                                // maxYear={1900}
                            />
                        ),
                    }}
                    onDayChange={handleToChange}
                />
            </div>
            <Helmet></Helmet>
        </div>
    );
}

export default Example;
