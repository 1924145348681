import rest from '@data/restWrapper';
import { ReservationModelWithModelsIncluded, MinibarLogWithIncludedModels, InitialAndlatestMinibarLogWithIncludedModels, MinibarLogExtended } from '@common/modelDefinition';
// import { startGlobalLoading, stopGlobalLoading } from '@components/loaders/globalLoading';
// import { closeModal } from '@components/modal';

export async function loadActiveReservation(roomInfoId: number, reservationId: number): Promise<ReservationModelWithModelsIncluded | null> {
    //ovo ce se mijenjati.. quick fix zbog bune
    //ne trazi vise rezervaciju po statusEnumima nego u glavu trazi preko reservationId ako se 'kači' preko forme (closed ili checkedIn)
    //ako cistacica 'kači' preko hauseholda onda trazi samo ako ima aktivna rezervacija na sobi (statusEnum = 2)
    let activeReservation = null
    if(reservationId){
         activeReservation = await rest('/getReservationsForRoom/' + roomInfoId + '?resId='+reservationId);
    }else{
        const roomReservations = await rest('/getReservationsForRoom/' + roomInfoId + '?statusEnum=2');
        if(roomReservations.length > 0){
            activeReservation = roomReservations[0]
        }
    }
    return activeReservation;
}

export async function loadMinibarItemsForRoom(roomInfoId: number): Promise<InitialAndlatestMinibarLogWithIncludedModels> {
    const enabledRoomMinibarItems = await rest('/getMinibarStatesForRoom/' + roomInfoId);
    let filterEnabledPosItemsMinibarLogs = []
    let initialfilterEnabledPosItemsMinibarLogs = []

    if(enabledRoomMinibarItems.filterEnabledPosItemsMinibarLogs){
        filterEnabledPosItemsMinibarLogs = enabledRoomMinibarItems.filterEnabledPosItemsMinibarLogs.sort(
            (a: MinibarLogExtended, b: MinibarLogExtended) => (a.posItemId > b.posItemId ? 1 : -1)
        );
    }
    if(enabledRoomMinibarItems.initialfilterEnabledPosItemsMinibarLogs){
        initialfilterEnabledPosItemsMinibarLogs = enabledRoomMinibarItems.initialfilterEnabledPosItemsMinibarLogs
    }

   
    return {filterEnabledPosItemsMinibarLogs, initialfilterEnabledPosItemsMinibarLogs};
}

export async function attachMinibarLogsToReservation(consumedMinibarItems: MinibarLogExtended[]) {
    for (const consumedMiniBarItem of consumedMinibarItems) {
        if (consumedMiniBarItem.newState !== consumedMiniBarItem.previousState) {
            const { roomInfoId, posItemId, previousState, newState, userUUID, reservationId } = consumedMiniBarItem;
            const newMinibarRecord = {
                roomInfoId,
                posItemId,
                previousState,
                newState,
                userUUID,
                reservationId,
                logType: 2,
                recordedAt: new Date().getTime(),
            };
            await rest('/saveMinibarLog', 'POST', [newMinibarRecord]);
        }
    }
}

export async function getMinibarConsumption(reservationId: number): Promise<MinibarLogWithIncludedModels[]> {
    const todaysRoomMinibarLogs = await rest('/getMinibarConsumption/' + reservationId, 'POST', {
        from: new Date(),
        to: new Date(),
    });
    return todaysRoomMinibarLogs;
}

export async function delMinibarData(minibarLog: MinibarLogExtended) {
    await rest('/MinibarLog/' + minibarLog.id, 'DELETE');
}
