import React from 'react';
import PropTypes from 'prop-types';
import translate from '@data/translations';

const Header = ({ timebar, headerTopOffset = 0, sticky: { isSticky, sidebarWidth, headerHeight } = {} }) => {
    const floatingHeaderOnYScroll = {
        position: 'absolute',
        width: '100%',
        top: headerTopOffset,
        zIndex: '1',
    };

    return (
        <div
            style={isSticky ? { paddingTop: headerHeight, ...floatingHeaderOnYScroll } : { ...floatingHeaderOnYScroll }}
        >
            <div
                className={`rt-sidebar__header ${isSticky ? 'rt-is-sticky' : ''}`}
                style={isSticky ? { width: sidebarWidth } : {}}
            >
                {timebar.map(({ id, title }) => (
                    <div key={id} className="rt-timebar-key">
                        {translate(title)}
                    </div>
                ))}
            </div>
        </div>
    );
};

Header.propTypes = {
    sticky: PropTypes.shape({
        isSticky: PropTypes.bool.isRequired,
        headerHeight: PropTypes.number.isRequired,
        sidebarWidth: PropTypes.number.isRequired,
    }),
    timebar: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            title: PropTypes.string,
        }).isRequired
    ).isRequired,
};

export default Header;
