import { ReactComponent as Balcony } from '../../assets/custom/icons/amenities/balcony.svg';
import { ReactComponent as Bathtub_hydro } from '../../assets/custom/icons/amenities/bathtub_hydro.svg';
import { ReactComponent as Bathtub } from '../../assets/custom/icons/amenities/bathtub.svg';
import { ReactComponent as Coffee } from '../../assets/custom/icons/amenities/coffee.svg';
import { ReactComponent as Double_bed } from '../../assets/custom/icons/amenities/double_bed.svg';
import { ReactComponent as Kettle } from '../../assets/custom/icons/amenities/kettle.svg';
import { ReactComponent as Minibar } from '../../assets/custom/icons/amenities/minibar.svg';
import { ReactComponent as Mountains } from '../../assets/custom/icons/amenities/mountains.svg';
import { ReactComponent as Oven } from '../../assets/custom/icons/amenities/oven.svg';
import { ReactComponent as Shower } from '../../assets/custom/icons/amenities/shower.svg';
import { ReactComponent as Single_bed } from '../../assets/custom/icons/amenities/single_bed.svg';
import { ReactComponent as Snowflake } from '../../assets/custom/icons/amenities/snowflake.svg';
import { ReactComponent as Television } from '../../assets/custom/icons/amenities/television.svg';
import { ReactComponent as Terrace } from '../../assets/custom/icons/amenities/terrace.svg';
import { ReactComponent as Washing_machine } from '../../assets/custom/icons/amenities/washing_machine.svg';
import { ReactComponent as Private_pool } from '../../assets/custom/icons/amenities/private_pool.svg';

export const attributeComponents = {
    atr_balcony: Balcony,
    atr_hydromassageBath: Bathtub_hydro,
    atr_bathtub: Bathtub,
    atr_coffeeTeaMaker: Coffee,
    atr_doubleBed: Double_bed,
    atr_kettle: Kettle,
    atr_minibar: Minibar,
    atr_view: Mountains,
    atr_kitchen_kitchenette: Oven,
    atr_privateBathroom: Shower,
    atr_bedForOnePerson: Single_bed,
    atr_airConditioning: Snowflake,
    atr_flatScreenTV: Television,
    atr_terrace: Terrace,
    atr_washingMachine: Washing_machine,
    atr_privatePool: Private_pool,
};
