import React from 'react'
import { ReactComponent as Hamburger } from '../../assets/custom/icons/menu.svg';
import SideMenu from './index';

export default function SideMenuMobile(props) {
    const [showSideMenu, setshowSideMenu] = React.useState(false)
    const [isMobileView, setIsMobileView] = React.useState(false)

    React.useEffect(() => {
        if (window.innerWidth <= 768) {
            setIsMobileView(true)
        }
    }, [])

    function showMenu(params) {
        setshowSideMenu(true) 
    }

    return (

        <div className='hamburger d-print-none' id="hamburger-mobile" style={{ position: "fixed", top: "10px", right: "10px" }}>
            <Hamburger onClick={() => { showMenu() }} />
            {showSideMenu ? <SideMenu {...props} isMobileView={isMobileView} showSideMenuProp={setshowSideMenu} /> : null}


        </div>

    )
}
