import rest from '@data/restWrapper';
import useStore from '@data/state/zustand';
import { Buffer } from 'buffer';

export const openDocument = (docInfo: any) => {
    const token: string =  useStore.getState().authTokenInfo?.token

    if (process.env.NODE_ENV === 'development') {
        window.open(
            'http://localhost:7777/getDocument/' + docInfo.uuid + '?token=' + Buffer.from(token).toString('base64')
        );
    } else {
        window.open('/getDocument/' + docInfo.uuid + '?token=' + Buffer.from(token).toString('base64'));
    }
};

export const deleteDocument = async (docInfo: any) => {
    try {
        await rest('/deleteDocument/' + docInfo.uuid, 'DELETE');
   
    } catch (err) {
        console.warn(err);
    }
};