import React from 'react';
import { CustomerModel, ReservationModel } from '@common/modelDefinition';
import { getOrCreateCustomerFromDocScan } from '@pages/newReservations/api';
import moment from 'moment';
import translate from '@data/translations';
import countryCodes from '@data/countryCodesIndexed';
import { toast } from 'react-toastify';
import useStore from '@data/state/zustand';

interface DocumentScannerProps {
    customer: CustomerModel | null;
    docScan: any[];
    reservation?: ReservationModel | null;
    setCustomersAfterScan: any;
    setShowDocumentScannerData: any;
    visa?: boolean;
}

const addUser = async (
    documentScan: any,
    customer: CustomerModel | null,
    reservation: ReservationModel | null,
    setCustomersAfterScan: any,
    setShowDocumentScannerData: any,
    visa: boolean
) => {
    try {
        const data: CustomerModel = await getOrCreateCustomerFromDocScan(
            documentScan.id,
            reservation?.id,
            customer?.id,
            visa
        );
        setCustomersAfterScan(data);
        setShowDocumentScannerData(false);
    } catch (error: any) {
        console.log('err', error);
        toast(error.message, { position: toast.POSITION.BOTTOM_RIGHT, type: toast.TYPE.ERROR });
    }
};

const DocumentScanner = (props: DocumentScannerProps) => {
    const { customer, docScan, reservation, setCustomersAfterScan, setShowDocumentScannerData, visa = false } = props;

    const otherSettings = useStore.getState().otherSettings;

    const startOfDay = new Date();
    startOfDay.setHours(0, 0, 0, 0);
    let _docScans = docScan?.filter(
        (d: any) => d.IDNumber !== null && new Date(d._DateOfScan).getTime() > startOfDay.getTime()
    );

    _docScans.sort(function (a: any, b: any) {
        return a.id - b.id;
    });

    if (_docScans.length > 0) {
        return (
            <div>
                <div className={'custom-list-header'}>
                    <div>{translate('Doc Type')}</div>
                    <div>{translate('Name')}</div>
                    <div>{translate('Document number')}</div>
                    <div>{translate('Birth date')}</div>
                    <div>{translate('Address')}</div>
                    <div>{translate('Scan time')}</div>
                </div>
                {_docScans.map((d: any, key: number) => {
                    const animatedClass = key === _docScans.length - 1 ? 'animated  pulse' : '';
                    return (
                        <div
                            key={key}
                            onClick={() => {
                                addUser(
                                    d,
                                    customer,
                                    reservation ? reservation : null,
                                    setCustomersAfterScan,
                                    setShowDocumentScannerData,
                                    visa
                                );
                            }}
                            className={animatedClass + ' custom-list-item pointer '}
                        >
                            <div>{d._DocumentType}</div>
                            <div>{d.FirstName + ' ' + d.LastName}</div>
                            <div>{d.IDNumber}</div>
                            <div>
                                {d.BirthDate
                                    ? moment(d.BirthDate).format(
                                          otherSettings?.dateFormat ? otherSettings.dateFormat : 'DD/MM/YYYY'
                                      )
                                    : ''}
                            </div>
                            <div>{d.Address + ', ' + d.City}</div>
                            <div>{moment(d.createdAt).format('DD/MM/YY HH:mm')}</div>
                        </div>
                    );
                })}
            </div>
        );
    } else {
        return <p className="p-2 ml-2">{translate('List empty...')}</p>;
    }
};

interface CustomersListAfterScannProps {
    setCustomer: any;
    customersAfterScan: any;
    setCustomersAfterScan: any;
}

const CustomersListAfterScann = (props: CustomersListAfterScannProps) => {
    const numOfCustomers = props.customersAfterScan.data.length === 1 ? ' customer' : ' few customers';
    return (
        <div>
            <p className="">
                There is a {numOfCustomers} with similar data. Select one that match with your data or create new with
                scanned data if no data match.{' '}
            </p>
            {props.customersAfterScan.data.map((c: CustomerModel, key: number) => {
                const country = c.country ? countryCodes[c.country] : null;
                return (
                    <div
                        key={key}
                        onClick={() => {
                            props.setCustomer(c);
                            props.setCustomersAfterScan({ ...props.customersAfterScan, data: [] });
                        }}
                        className={'custom-list-item '}
                    >
                        <div>#{c.id}</div>
                        <div>{c.firstName + ' ' + c.lastName}</div>
                        <div>{c.documentId}</div>
                        <div>{c.dateOfBirth ? moment(c.dateOfBirth).format('DD/MM/YYYY') : ''}</div>
                        <div>{c.town + ', ' + translate(country ? country.name : '')}</div>
                    </div>
                );
            })}
        </div>
    );
};

export { DocumentScanner, CustomersListAfterScann };
