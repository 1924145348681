import React from 'react'
import PropTypes from 'prop-types'

import TrackKeys from './TrackKeys'

const Body = ({ tracks, toggleTrackOpen, clickTrackButton, trackButtonIcon }) => {
	const headerOffset = 62
	return   <div className="rt-sidebar__body" style={{paddingTop:headerOffset}}>
    <TrackKeys tracks={tracks} toggleOpen={toggleTrackOpen} clickTrackButton={clickTrackButton} trackButtonIcon={trackButtonIcon} />
  </div>
}


Body.propTypes = {
  tracks: PropTypes.arrayOf(PropTypes.shape({})),
  toggleTrackOpen: PropTypes.func,
  clickTrackButton: PropTypes.func,
  trackButtonIcon: PropTypes.element,
}

export default Body
