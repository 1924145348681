import React from 'react'

export default 
//@ts-ignore
function YearMonthForm({ onChange, date, minYear, maxYear=new Date().getFullYear() + 100, formOrDiv="form" }) {
    // const date = new Date()
    const months = [0,1,2,3,4,5,6,7,8,9,10,11];
  
    const years = [];
//@ts-ignore
    for (let i = minYear; i <= maxYear; i += 1) {
      years.push(i);
    }
  
    const handleChange = function handleChange(e:any) {
      const { year, month } = e.target.form;
      onChange(new Date(year.value, month.value));
    };
  
    if(formOrDiv === "form"){
        return (
            <form className="DayPicker-Caption custom-year-month-select-dayPicker">
              <select className='input input-modal mr-4p' name="month" onChange={handleChange} value={date.getMonth()}>
                {
      //@ts-ignore
      
                months.map((month, i) => (
                  <option key={month} value={i}>
                    {month +1}
                  </option>
                ))}
              </select>
              <select className='input input-modal' name="year" onChange={handleChange} value={date.getFullYear()}>
                {years.map(year => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </form>
          );
    }else{
        return (
            <div className="DayPicker-Caption custom-year-month-select-dayPicker">
              <select className='input input-modal mr-4p' name="month" onChange={handleChange} value={date.getMonth()}>
                {
      //@ts-ignore
      
                months.map((month, i) => (
                  <option key={month} value={i}>
                    {month +1}
                  </option>
                ))}
              </select>
              <select className='input input-modal' name="year" onChange={handleChange} value={date.getFullYear()}>
                {years.map(year => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>
          );
    }
    
  }
